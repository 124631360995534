
.comments-blinks-text {
  color: #F59345;
  font-size: 14px;
}

.comments-after-blinks-text {
  color: black;
}

.comments-blinks-text.active {
  animation: blink 1.5s infinite;
}

@keyframes blink {
  0% {
    opacity: 1
  }
  50% {
    opacity: 0.5
  }
  100% {
    opacity: 1
  }
}

.supplier-view-files-wrapper {
  flex-direction: row-reverse !important;
  justify-content: start !important;
}

.supplier-view-files-wrapper {
  flex-direction: row-reverse !important;
  justify-content: start !important;
}

.supplier-rfq-view-page .part-list-tab-supplier .paint-code {
  margin-left: 50px !important;
}


.w-21 {
  width: 21%;
}

.block-uploader {
  margin-top: 15px;
}

.image-block {
  width: 864px;
  margin-top: 15px;
  margin-left: 15px;
}


.image-block {
  width: 100%;
  padding: 15px 15px 0 15px;
  margin-left: unset;
  margin-right: unset;
  margin-top: unset;
}

.files-diagram-wrapper {
  max-width: 600px;
}

.diagram-image {
  min-height: 300px;
  padding: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: none;
  cursor: pointer;
}

.files-diagram-block {
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-radius: 3px;
  margin-top: 30px;
  margin-bottom: 30px;
  min-height: 300px;
}

.files-diagram-block-active {
  border: 1px solid #29BBC1;
  border-radius: 3px;
  box-shadow: 0 0 0 0.2rem #29BBC1;
}

.diagram-block-image {
  min-height: 325px;
  border: none;
}

.diagram-image {
  padding: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.diagram-block-line {
  margin-left: 15px;
  margin-right: 15px;
  height: 2px;
  background-color: rgba(27, 30, 56, 0.25);
}

.diagram-block-button {
  margin: 15px;
}

.diagram-block-button button {
  padding: 10px 20px;
}

.diagram-block-button button:disabled {
  background-color: #a8a8a8 !important;
  border-color: #a8a8a8 !important;
  cursor: not-allowed;
}

.diagram-block-button span {
  color: rgba(0, 0, 0, .5);
  font-weight: normal;
}

@media screen and (min-width: 993px) {
  .image-block {
    width: 864px;
    padding: unset;
    margin-left: 15px;
    margin-top: 15px;
  }
}

.border-for-paint-code {
  animation-name: flashing;
  animation-timing-function: cubic-bezier(0.03, 0.25, 0.32, 1.28);
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes flashing {
  50% {
    box-shadow: inset 0 0px 1px 0px #29bbc1, 0 0 3px 1px #29bbc1;
  }
}

.import-dms-additional-block .customer-account-deliver {
  margin: 25px 0 0 0;
}

.border-for-paint-code {
  animation-name: flashing;
  animation-timing-function: cubic-bezier(0.03, 0.25, 0.32, 1.28);
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes flashing {
  50% {
    box-shadow: inset 0 0px 1px 0px #29bbc1, 0 0 3px 1px #29bbc1;
  }
}

.vehicle-details-information-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.vehicle-details-information-block p {
  margin-bottom: 0;
  font-weight: bold;
  color: #1C1F39;
  margin-right: 10px;
  margin-left: 20px;
  padding-top: 7px;
}


@media (max-width: 768px) {
  .tabs-component .tab-header.float-sm-right div.tabs-nav-right-btn, .tabs-component .tab-header.float-sm-right div.tabs-nav-right-options {
    display: block !important;
  }

  .theme-supplier .dropdown-options {
    width: 90px;
  }

  .theme-supplier .dropdown-options .dropdown-arrow {
    width: 20px;
  }

  .theme-supplier .dropdown-options .dropdown-arrow .bx-down-arrow {
    margin: 10px 10px 10px 5px;
  }

  .supplier-rfq-view-page .tabs-component .tab-header.float-sm-right div.tabs-nav-right-btn {
    display: none !important;
  }
}

.import-dms-additional-block .customer-account-deliver {
  margin: 25px 0 0 0;
}
