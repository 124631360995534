.font-inter {
    font-family: "Inter", sans-serif;
}
.font-inter--normal {
    font-weight: 400;
}
.font-inter--bold {
    font-weight: 700 !important;
}
.font-inter--semibold {
    font-weight: 600 !important;
}

.font-11 {
    font-size: 11px !important;
    line-height: 15px !important;
    letter-spacing: normal;
}
.font-12 {
    font-size: 12px !important;
    line-height: 16px !important;
    letter-spacing: normal;
}
.font-13 {
    font-size: 13px !important;
    line-height: 18px !important;
    letter-spacing: normal;
}
.font-14 {
    font-size: 14px !important;
    line-height: 19px !important;
    letter-spacing: normal;
}
.font-16 {
    font-size: 16px !important;
    line-height: 22px !important;
    letter-spacing: normal;
}
.font-18 {
    font-size: 18px !important;
    line-height: 24px !important;
    letter-spacing: normal;
}
.font-20 {
    font-size: 20px !important;
    line-height: 26px !important;
    letter-spacing: normal;
}

.word-wrap {
    white-space: normal;
}

.word-nowrap {
    white-space: nowrap;
}

.top-15 {
    top: 15px;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

/* .ml-15 {
    margin-left: 15px !important;
} */

.m-15 {
    margin: 15px !important;
}

.mx-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.p-15 {
    padding: 15px !important;
}

.px-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.pt-15 {
    padding-top: 15px !important;
}

/* more force to get margin 0 */
div.m-0 {
    margin: 0 !important;
}

.theme-repairer .theme-color {
    color: #79B1BB !important;
}

.theme-supplier .theme-color {
    color: #29BBC1 !important;
}

.color-lightgray {
    color: #a0a1a7 !important;
}
