:root {
    /* Color */
    --blue: #1c1f39;
    --turtoise: #29BBC1;
    --turtoise-10: #EAF8F9;
    --orange: #79B1BB;
    --orange-10: #FFF2EA;
    --gray-50: #eeeeee; /* testing */
    --gray-20: #1b1e3801;
    --white: #ffffff;
    --cadet-blue: #79B1BB;


    /* Color Intentions */
    --body-color: var(--blue);
    --supplier-color: var(--turtoise);
    --repairer-color: var(--cadet-blue);
    --theme-color: var(--supplier-color);
    --default-color: var(--gray-20);
    --border-color: var(--gray-20);


    /* Props */
    --border-radius: 3px;

    /*Type*/

    /*Spacing*/

    /*Character*/

    /*Elevation*/

    /*Elements*/
    --btn-initial-color: var(--default-color);
    --btn-border-radius: var(--border-radius);

}

.theme-repairer {
    --theme-color: var(--repairer-color)
}
