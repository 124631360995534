.V3 .theme-supplier .site-sidebar .sidebar-menu>li.active>a,
.V3 .V3.compact-sidebar .theme-supplier .site-sidebar .sidebar-menu li.active>a,
.V3 .V3.compact-sidebar .theme-supplier .site-sidebar .sidebar-menu li:hover>a {
    background: none;
    background-color: #131923;
}

.V3 .theme-supplier .site-sidebar {
    background: #131923;
}

.V3 .theme-supplier .site-sidebar .sidebar-menu li > a:hover {
    color: #29BBC1;
}

.V3 .theme-supplier .site-sidebar .sidebar-menu li.active > a {
    color: #29BBC1;
}

.V3 .theme-supplier .site-sidebar .sidebar-menu li.active > a > span > i {
    color: #29BBC1;
}

.V3 .theme-supplier .site-sidebar .sidebar-menu > li > a:hover .s-icon i {
    color: #29BBC1;
}

.V3.compact-sidebar .theme-supplier .site-sidebar .sidebar-menu li a:hover {
    border: 1px solid #29BBC1;
}

.V3 .V3.compact-sidebar .theme-supplier .site-sidebar .sidebar-menu li:hover > a > .s-text {
    display: block;
    color: #29BBC1 !important;
  }