
.ps-label-value {
    min-width: 360px;
    font-size: 13px;
    span {
        display: inline-block;
        margin-bottom: 5px;
        vertical-align: top;
    }
    &__label {
        width: 170px;
        white-space: nowrap;
        color: #a0a1a7;
        font-weight: 700;
    }
    &__colon {
        width: auto;
        margin-left: 15px;
        margin-right: 15px;
    }
    &__value {
        font-weight: 700;
    }

}
