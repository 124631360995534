/* Radio */
.theme-repairer label.radio > span.icon {
    border: 2px solid #79B1BB !important;
    background-color: rgba(27, 30, 56, 0.03) !important;
}

.theme-repairer label.radio > input[type="radio"]:checked + span.icon {
    background-color: rgba(27, 30, 56, 0.03);
}
.theme-repairer label.radio > input[type="radio"]:checked:disabled + span.icon {
    background-color: rgba(27, 30, 56, 0.03);
}
.theme-repairer label.radio > input[type="radio"]:checked + span.icon i {
    transform: translate(-2px, -2px);
    color: #79B1BB
}
.theme-repairer label.radio > input[type="radio"]:checked:disabled + span.icon i {
    color:#000;
}
/* Checkbox
 */
.theme-repairer label.checkbox > span.icon,
.tooltip-inner label.checkbox > span.icon {
    border: 2px solid #79B1BB !important;
    background-color: rgba(27, 30, 56, 0.03) !important;
}
.theme-repairer label.checkbox > input[type="checkbox"]:checked + span.icon,
.tooltip-inner label.checkbox > input[type="checkbox"]:checked + span.icon {
    background-color: rgba(27, 30, 56, 0.03);
}
.theme-repairer label.checkbox > input[type="checkbox"]:checked:disabled + span.icon,
.tooltip-inner label.checkbox > input[type="checkbox"]:checked:disabled + span.icon {
    background-color: rgba(27, 30, 56, 0.03);
}
.theme-repairer label.checkbox > input[type="checkbox"]:checked + span.icon i,
.tooltip-inner label.checkbox > input[type="checkbox"]:checked + span.icon i {
    transform: translate(-2px, -2px);
    color: #79B1BB;
}
.theme-repairer label.checkbox > input[type="checkbox"]:checked:disabled + span.icon i,
.tooltip-inner label.checkbox > input[type="checkbox"]:checked:disabled + span.icon i {
    color:#000;
}
