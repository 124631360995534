.theme-repairer .pagination-block-showing span {
    color: #1C1F39;
    opacity: 0.5;
}

.theme-repairer .pagination .page-item .page-link {
    box-sizing: border-box;
    border: 1px solid rgba(27,30,56,0.25);
    border-radius: 3px;
    background-color: rgba(27,30,56,0.03);
}

.theme-repairer .pagination .page-item.active .page-link {
    background-color: #79B1BB!important;
    box-shadow: none;
    border: none;
}

.theme-repairer .pagination .page-item.active .page-link,
.theme-repairer .pagination .page-item.active .page-link:hover,
.theme-repairer .pagination .page-item.active .page-link:focus{
    color:#fff;
    box-shadow: none;
    border: none;
}
.theme-repairer .pagination .page-item .page-link,
.theme-repairer .pagination .page-item .page-link:hover,
.theme-repairer .pagination .page-item .page-link:focus {
    box-shadow: none;
}
