.theme-supplier .wizard__step:not(:first-child) .wizard__step__line {
    position: absolute;
    width: 100%;
    left: -50%;
    top: 17px;
    height: 10px;
    /*background-color: #29BBC1;*/
}

.theme-supplier .wizard__step__indicator {
    background-color: #29BBC1;
}

.theme-supplier .wizard__step__indicator i {
    color: #29BBC1;
}

.theme-supplier .wizard__step.active.current .wizard__step__indicator {
    background-color: #FFFFFF;
    display: block;
    border: 2px solid #29BBC1;
}

.theme-supplier .wizard__step.active:not(:first-child) .wizard__step__line {
    background-color: #29BBC1;
}

.theme-supplier .wizard__step:nth-child(2).new-request .wizard__step__line{
    background-image: linear-gradient(to right, #29BBC1 30%, #e3e4e7 30% 100%);
}