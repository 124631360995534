.block-uploader {
}

.block-uploader .dropify-message {
    transform: translateY(0%);
}

.block-uploader .drop-down-text {
    color: #1C1F39;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
}

.block-uploader .text-or {
    opacity: 0.25;
    color: #1C1F39;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
}

.block-uploader .browse-btn {
    margin-right: 10px;
    width: 126px;
}

.block-uploader .cancel-btn {
    width: 126px;
}

.block-uploader i.bx-cloud-upload {
    font-size: 42px;
    color: #79B1BB;
}

.block-uploader.image-upload,
.image-block {
    width: 100%;
    padding: 15px 15px 0 15px;
}

@media screen and (min-width: 993px) {
    .block-uploader.image-upload,
    .image-block
    {
        width: 864px;
        padding: unset;
        margin-left: 15px;
        margin-top: 15px;
    }
}
