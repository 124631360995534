/* Radio */
.theme-supplier label.radio>span.icon {
    border: 2px solid #29BBC1 !important;
    background-color: rgba(27, 30, 56, 0.03) !important;
}

.theme-supplier label.radio>input[type="radio"]:checked+span.icon {
    background-color: rgba(27, 30, 56, 0.03);
}

.theme-supplier label.radio>input[type="radio"]:checked:disabled+span.icon {
    background-color: rgba(27, 30, 56, 0.03);
}

.theme-supplier label.radio>input[type="radio"]:checked+span.icon i {
    transform: translate(-2px, -2px);
    color: #29BBC1;
}

.theme-supplier label.radio>input[type="radio"]:checked:disabled+span.icon i {
    color: #000;
}

/* Checkbox
 */
.theme-supplier label.checkbox>span.icon {
    border: 2px solid #77afb9 !important;
    background-color: rgba(27, 30, 56, 0.03) !important;
}

.theme-supplier label.checkbox>input[type="checkbox"]:checked+span.icon {
    background-color: rgba(27, 30, 56, 0.03);
}

.theme-supplier label.checkbox>input[type="checkbox"]:checked:disabled+span.icon {
    background-color: rgba(27, 30, 56, 0.03);
}

.theme-supplier label.checkbox>input[type="checkbox"]:checked+span.icon i {
    transform: translate(-2px, -2px);
    height: 25px;
    border-radius: 3px;
    color: #fdfdfd;
    background-color: #77afb9;
}

.theme-supplier label.checkbox>input[type="checkbox"]:checked:disabled+span.icon i {
    color: #000;
}
