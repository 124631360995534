.ps-tooltip.tooltip.b-tooltip {
    margin-top: 5px;
}

.ps-tooltip.tooltip.b-tooltip .tooltip-inner {
    border-radius: 10px 0 10px 10px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 8px 0 rgba(27, 30, 56, 0.25);
    color: #1C1F39;
    font-family: "Inter";
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    padding: 10px;
}
.ps-tooltip.tooltip.b-tooltip.ps-tooltip-round .tooltip-inner {
    border-radius: 10px;
}

.ps-tooltip.tooltip.b-tooltip .arrow {
    display: none;
}

.ps-tooltip--table > * {
    position: relative;
    top: -10px;
    right: 15px;
}
.ps-tooltip--table[x-placement="top"] > * {
    top: 5px;
    border-radius: 10px 10px 0px 10px !important;
}

.ps-tooltip.tooltip-rfq-add {
    top: 27px !important;
    left: 10px !important;
}


.ps-tooltip-status-icon.tooltip.b-tooltip {
    margin-top: 5px;
    top: 5px !important;
    left: 4px !important;
}
.ps-tooltip-status-icon p{
    margin:0;
}

.ps-tooltip-status-icon {
    padding: 5px !important;
}

.ps-tooltip-status-icon-changed .tooltip-inner {
    max-width: 300px !important;
}

.ps-tooltip-status-icon.tooltip.b-tooltip .tooltip-inner {
    border-radius: 10px 0 10px 10px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 8px 0 rgba(27, 30, 56, 0.25);
    color: #1C1F39;
    font-family: "Inter";
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    padding: 10px;
}
.ps-tooltip-status-icon.tooltip.b-tooltip.ps-tooltip-round .tooltip-inner {
    border-radius: 10px;
}

.ps-tooltip-status-icon.tooltip.b-tooltip .arrow {
    display: none;
}

.ps-tooltip-status-icon--table > * {
    position: relative;
    top: -10px;
    right: 15px;
}
.ps-tooltip-status-icon--table[x-placement="top"] > * {
    top: 5px;
    border-radius: 10px 10px 0px 10px !important;
}
