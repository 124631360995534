.block .block-name{
    color: #1C1F39;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 20px;
}

.block .block-value{
    color: #1C1F39;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
}
