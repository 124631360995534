
.credit-management {
  &__context-menu {
    .btn {
      border: none;
      padding: 15px 15px 11px 15px;
      transition-duration: 150ms;
    }

    .theme-repairer & .btn:hover {
      color: #070707;
    }
  }
}

.repairer-credit-page {
  .v-context {
    width: auto !important;
    border: none;
    box-shadow: rgba(27, 30, 56, 0.15);
  }
  .amount {
    text-align: end;
    padding-right: 3rem;
  }
}
