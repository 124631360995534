.dashboard-page {
  background: #f1f1f7;
}

.V3 .dashboard-panel, .dashboard-panel {
  border-radius: 5px;
}

.dashboard-panel .card-body {
  padding-top: 15px;
  padding-bottom: 15px;
}

.dashboard-panel .card-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.dashboard-panel .card-title .title-text {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.dashboard-panel .search {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 374px;
}

.dashboard-panel .search input {
  width: 100%;
}

.dashboard-panel .search .btn {
  flex-shrink: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dashboard-panel .search .input-text {
  margin-right: 15px;
  width: 100%;
}

.cards-list {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(179px, 1fr));
}

.cards-list .card-item {
  color: #1c1f39;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-radius: 5px;
}

.cards-list .card-item.min-height {
  min-height: 271px;
}

.cards-list .card-item a {
  color: #1c1f39;
}

.cards-list .card-item .header-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid rgba(27, 30, 56, 0.25);
}

.cards-list .card-item .header-item i {
  font-size: 24px;
  color: #000;
}

.cards-list .card-item .body-item {
  padding: 15px;
  padding-top: 9.5px;
  padding-bottom: 9.5px;
}

.list-values {
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
}
.list-values ul {
  padding: 0;
  margin-top: 28px;
  list-style: none;
  margin-bottom: 13px;
}

.list-values ul li {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
}

.list-values ul li+li {
  margin-top: 15px;
}

.list-values ul li i {
  font-size: 28px;
  margin-right: 10px;
}

.list-values ul li strong {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  display: block;
}

.list-values .list-values-header.orange-bg {
  background: rgba(41, 187, 193, 0.1);
}

.list-values .list-values-header.aqua-bg {
  background: rgba(41, 187, 193, 0.1);
}

.list-values .list-values-header {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
}

.list-values .list-values-header .numb {
  font-size: 28px;
  line-height: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  margin-right: 5px;
}

.theme-supplier .list-values .list-values-header .numb {
  color: var(--supplier-color);
}

.theme-repairer .list-values .list-values-header .numb {
  color: var(--repairer-color);
}

.theme-supplier .list-values .list-values-header .numb {
  color: #29BBC1
}

.tui-chart .tui-chart-tooltip-area .tooltip-template {
  position: relative;
  height: 75px;
  width: 157px;
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
  color: #1C1F39;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  font-family: 'Inter', sans-serif;
}

.tui-chart .tui-chart-tooltip-area .tooltip-template:after {
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -8px;
  bottom: -14px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 8px 0 8px;
  border-color: #ffffff transparent transparent transparent;
}

.tui-chart .tui-chart-tooltip-area .tooltip-template .tt-title {
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
}

.tui-chart .tui-chart-tooltip-area .tooltip-template .point {
  box-sizing: border-box;
  height: 17px;
  width: 17px;
  border: 3px solid #FFFFFF;
  background-color: #FFCFB1;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  bottom: -30px;
  border-radius: 50%;
  left: 50%;
  margin-left: -9px
}

.theme-supplier .tui-chart .tui-chart-tooltip-area .tooltip-template .point {
  background-color: #A4EEF1;
}

.tui-chart .tui-chart-tooltip-area .tooltip-template .tt-body {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
}

.tui-chart .tui-chart-tooltip-area .tooltip-template .tt-body .tt-value {
  font-weight: 500;
}

.tui-chart .tui-chart-tooltip-area .tooltip-template ul {
  padding: 0;
  margin: 0;
  margin-top: 5px;
  list-style: none;
}

.tui-chart .tui-chart-tooltip-area .tooltip-template ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
