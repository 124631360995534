/*
=======================================
Pin Images
==========================================
 */

.nav-pin-images {
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    position: relative;
    color: #1C1F39;
    font-weight: 600;
    font-size: 13px;
    padding-top: 0px;
    padding-left: 0px;
    margin-bottom: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.tab-header .nav-pin-images {
    margin-right: 43px;
}

.V3 .tab-header label.form-check-inline.nav-pin-images > span.text {
    width: 66px;
}

.image-pin-area {
    position: fixed;
    width: 240px;
    height: calc(100% - 60px);
    overflow-y: auto;
    display: block;
    background: #131923;
    left: 0;
    z-index: 999999;
    top: 62px;
    padding: 20px 15px;

    -ms-overflow-style: none;

    /* hide scroll on firefox */
    scrollbar-width: none;
}

.image-pin-area::-webkit-scrollbar {
    display: none;
}


/* on compact sidebar */
.compact-sidebar .image-pin-area {
    left: -150px;
}

.compact-sidebar .image-pin-area .pin-image {
    width: 65px;
    margin-left: 135px;
    padding-left: 0;
    padding-top: 40px;
    height: unset;
    text-align: center;
}

.compact-sidebar .pin-checkmark {
    margin-left: 20px;
}

/* on mobile phone */
.site-sidebar-opened .image-pin-area {
    left: 0px;
}

.site-sidebar-opened .image-pin-area .pin-image {
    width: unset;
    margin-left: unset;
    padding-left: 35px;
    padding-top: 8px;
    height: unset;
    text-align: left;
}

.site-sidebar-opened .pin-checkmark {
    margin-left: unset;
}

.V3 .compact-sidebar.site-sidebar-opened .pin_images .img-box__a {
    height: 160px;
}


.V3 .compact-sidebar .pin_images .img-box__a {
    height: 60px;
}

.image-pin-area .dropzone {
    padding-top: 0;
    margin-bottom: 15px;
}

.image-pin-area .dropzoneContainer {
    margin: 0;
    padding-top: 5px;
}

.image-pin-area .dropzone .dz-preview {
}

.image-pin-area .dropzone .dz-message {
    margin-top: 30px 0 35px 0;
}

.image-pin-area .dropzone .dz-message i.bx-cloud-upload {
    font-size: 57px;
    color: #FFFFFF;
    margin-bottom: 11px;

}

.image-pin-area .dropzone .dz-message p {
    color: #FFFFFF;
    font-family: "Inter";
    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
    letter-spacing: 0;
}

.image-pin-area .dropify-message i,
.image-pin-area .dropify-message p {
    margin-bottom: 0;
}

.image-pin-area .dropify-wrapper {
    width: 100%;
    height: 160px;
    border-radius: 5px;
    border-width: 0;
    background-color: rgba(255, 255, 255, 0.1) !important;
    margin-bottom: 30px;
}

.image-pin-area .col-4 {
    width: 100%;
    padding: 0;
}

.image-pin-area .col-4 .box.box-block.bg-grey {
    padding: 0;
}

.image-pin-area .col-4 .box.box-block.bg-grey .wrap.img-fluid {
    width: 100%;
    height: auto;
}

.pin-image {
    display: flex;
    align-items: center;
    position: relative;
    color: #ffffff; /* #34495E; */
    height: 27px;
    font-weight: 600;
    font-size: 13px;
    padding-top: 8px;
    padding-left: 35px;
    margin-bottom: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

@media screen and (max-width: 991px) {
    .pin-image {
        margin-bottom: 15px;
        color: #ffffff;
    }
}


/* Hide the browser's default checkbox */
.pin-image input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.pin-checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 3px;
    background-color: white;
    border: 1px solid #DFDFDF;
    padding: 2px 5px;
}

/* On mouse-over, add a grey background color */
.pin-image:hover input ~ .pin-checkmark {
    background-color: #CCCCCC;
    border-color: #CCCCCC;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

/* When the checkbox is checked, add a blue background */
.pin-image input:checked ~ .pin-checkmark {
    background-color: #5E79FF;
    border-color: #5E79FF;
}

/* supplier theme checkmark */
.theme-supplier .pin-image input:checked ~ .pin-checkmark {
    background-color: #29BBC1;
    border-color: #29BBC1;
}

/* repairer theme checkmark */
.theme-repairer .pin-image input:checked ~ .pin-checkmark {
    background-color: #79B1BB;
    border-color: #79B1BB;
}

/* Create the checkmark/indicator (hidden when not checked) */
.pin-checkmark i {
    margin: auto;
    width: 16px;
    margin-top: 3px;
    display: none;
}

#job-card .pin-checkmark i {
    font-size: 26px;
    color: white;
    margin-left: -2px;
    margin-top: -2px;
}

/* Show the checkmark when checked */
.pin-image input:checked ~ .pin-checkmark i {
    display: block;
}

/* Style the checkmark/indicator */
.pin-image .pin-checkmark:after {
}
