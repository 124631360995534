
.btn--remove {
    position: absolute;
    top: 15px;
    right: 15px;
    width: auto !important;
    font-size: 20px !important;
    border: 0;

    &:hover {
        .theme-repairer & {
            color: var(--repairer-color);
        }

        .theme-supplier & {
            color: var(--info);
        }
    }
}

.btn--pin {
    position: absolute;
    color: var(--repairer-color);
    top: 15px;
    left: 15px;
    width: auto !important;
    font-size: 20px !important;
    border: 0;

    &:hover {
        .theme-repairer & {
            color: var(--repairer-color);
        }

        .theme-supplier & {
            color: var(--info);
        }
    }
}

.disabled-button-pin {
    background-color: #FFFFFF;
    font-weight: bold;
    line-height: 18px;
    box-sizing: border-box;
    border: none;
    border-radius: 3px;
    cursor: default !important;
}

.gallery {
    &-content {
        min-height: 100vh;
    }

    &__gray-bg {
        display: none;
    }
}

button.btn {
    font-size: 13px;
    line-height: 18px;

    &--edit {
        margin-left: 15px;
        background-color: transparent;
        color: #79B1BB;
        border-color: #79B1BB;

        &:hover {
            color: #fff;
            background-color: #79B1BB;
        }
    }

    &--download {
        margin-left: 15px;
        color: #fff;
        background-color: #79B1BB;
        border-color: #79B1BB;
    }
}

.gallery-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #fff;
}

.gallery-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10010;
    position: fixed;
    outline: none !important;
    background-color: #F3F4F5;
    -webkit-backface-visibility: hidden;
}

.gallery-container {
    text-align: center;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    padding-left: 240px;
    min-height: 100vh;
    box-sizing: border-box;
    background-color: rgba(27, 30, 56, 0.05);

    .compact-sidebar & {
        padding-left: 70px;
    }
}

// .gallery-container:before {
//   content: '';
//   display: inline-block;
//   height: 100%;
//   vertical-align: middle;
// }

.gallery-align-top .gallery-container:before {
    display: none;
}

.gallery-content {
    position: relative;
    display: inline-block;
    padding-top: 59px; // site-header height
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
}

.gallery-inline-holder .gallery-content,
.gallery-ajax-holder .gallery-content {
    width: 100%;
    cursor: auto;
}

.gallery-ajax-cur {
    cursor: progress;
}

.gallery-zoom-out-cur, .gallery-zoom-out-cur .gallery-image-holder .gallery-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
}

.gallery-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}

.gallery-auto-cursor .gallery-content {
    cursor: auto;
}

.gallery-close,
.gallery-arrow,
.gallery-preloader,
.gallery-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.gallery-loading.gallery-figure {
    display: none;
}

.gallery-hide {
    display: none !important;
}

.gallery-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044;
}

.gallery-preloader a {
    color: #CCC;
}

.gallery-preloader a:hover {
    color: #FFF;
}

.gallery-s-ready .gallery-preloader {
    display: none;
}

.gallery-s-error .gallery-content {
    display: none;
}

button.gallery-close,
button.gallery-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

.gallery-close {
    width: 44px;
    height: 28px;
    line-height: unset;
    position: relative;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    padding: 0 0 0 0;
    color: #FFF;
    font-style: normal;
    font-size: 48px;
    font-family: Arial, Baskerville, monospace;
}

.gallery-close-icon:hover,
.gallery-close-icon:focus {
    opacity: 1;
}

.gallery-close-icon {
  opacity: 0.65;
}

//.gallery-close:active {
//    top: 1px;
//}

.gallery-close-btn-in .gallery-close {
    color: #333;
}

.gallery-image-holder .gallery-close,
.gallery-iframe-holder .gallery-close {
    position: absolute;
    width: 100%;
    height: auto;

    color: #000000;
    right: -6px;
    text-align: right;
    padding-top: 5px;
    line-height: 48px;
}

.gallery-counter {
    color: #1C1F39;
    font-size: 16px;
    line-height: 22px;
    margin: 15px 0;
    white-space: nowrap;
}

.gallery-arrow {
    position: absolute;
    opacity: 0.65;
    margin: 0;
    top: 50%;
    font-size: 48px;
    margin-top: -55px;
    padding: 0;
    width: 70px;
    height: 110px;
    -webkit-tap-highlight-color: transparent;
}

.gallery-arrow:active {
    // margin-top: -54px;
}

.gallery-arrow:hover,
.gallery-arrow:focus {
    opacity: 1;
}

.gallery-arrow:before,
.gallery-arrow:after {
    // content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent;
}

.gallery-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px;
}

.gallery-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7;
}

.gallery-arrow-left {
    left: 0;
}

.gallery-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px;
}

.gallery-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F;
}

.gallery-arrow-right {
    right: 0;
}

.gallery-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px;
}

.gallery-arrow-right:before {
    border-left: 27px solid #3F3F3F;
}

.gallery-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
}

.gallery-iframe-holder .gallery-content {
    line-height: 0;
    width: 100%;
    max-width: 900px;
}

.gallery-iframe-holder .gallery-close {
    top: -40px;
}

.gallery-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
}

.gallery-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000;
}

/* Main image in popup */
img.gallery-img {
    display: block;
    width: 100%;
    height: auto;
    max-width: 100%;
    min-height: 20vh;
    margin: 0 auto;
    line-height: 0;
    background-color: whitesmoke;
    box-sizing: border-box;
    border-radius: 5px;
    object-fit: contain;
}

/* The shadow behind the image */
.gallery-figure {
    line-height: 0;
}

.gallery-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
}

.gallery-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px;
}

.gallery-figure {
    figure {
        margin: 0;
        overflow: hidden;
    }
}

.gallery-bottom-bar {
    cursor: auto;
}

.gallery-title {
    text-align: left;
    line-height: 18px;
    color: #F3F3F3;
    word-wrap: break-word;
    padding-right: 36px;
}

.gallery-image-holder .gallery-content {
    max-width: 90%;
}

.gallery-gallery .gallery-image-holder .gallery-figure {
    // cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
    /**
    * Remove all paddings around the image on small screen
    */
    .gallery-container {
        padding-left: 70px;
    }
    .gallery-img-mobile .gallery-image-holder {
        padding-left: 0;
        padding-right: 0;
    }
    .gallery-img-mobile img.gallery-img {
        padding: 0;
    }
    .gallery-img-mobile .gallery-figure:after {
        top: 0;
        bottom: 0;
    }
    .gallery-img-mobile .gallery-figure small {
        display: inline;
        margin-left: 5px;
    }
    .gallery-img-mobile .gallery-bottom-bar:empty {
        padding: 0;
    }
    .gallery-img-mobile .gallery-counter {
        right: 5px;
        top: 3px;
    }
    .gallery-img-mobile .gallery-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        text-align: center;
        padding: 0;
    }
}

@media all and (max-width: 900px) {

    .gallery-arrow {
        -webkit-transform: scale(0.75);
        transform: scale(0.75);
    }

    .gallery-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0;
    }

    .gallery-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%;
    }

    .gallery-img-mobile .gallery-bottom-bar {
        margin-bottom: 3rem;
    }

    .gallery-image-holder {

        .gallery-content {
            max-width: 80%;
        }

        .gallery-close {
            padding-top: 0;
            right: -15%;
            font-size: 28px;
        }

    }

    .gallery {
        &-container {
            padding-left: 160px; // compensate offset
            background-color: white;

            .compact-sidebar & {
                padding-left: 6px;
                padding-right: 6px;
            }
        }

        &-arrow {
            margin-top: -95px;
        }

        &-content {
            min-height: 72vh;
            padding-top: 3rem;
        }

        &__gray-bg {
            display: block;
            width: 100%;
            min-height: 38vh;
            background-color: rgba(27, 30, 56, 0.05);
        }

        &-arrow .bx {
            font-size: 42px;
        }

        &-arrow-left {
            left: 15px;
        }

        &-arrow-right {
            right: 15px;
        }
    }
}

/**
 * Mobile Phones
 */
@media all and (max-width: 426px) {

    .gallery-image-holder {

        .gallery-content {
            max-width: 100%;
        }

        .gallery-close {
            padding-top: 0;
            right: unset;
            font-size: 28px;
        }

    }

    .gallery {

        &-container {
            padding-left: 0;
            padding-right: 0;
        }

        &-arrow {
            margin-top: -95px;
        }

        &-arrow .bx {
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.5);
            font-size: 38px;
        }

        &-arrow-left {
            left: 10px;
        }

        &-arrow-right {
            right: 10px;
        }
    }
    .btn-container {
        width: 100%;
    }

    button.btn {
        display: block;
        width: 100%;

        &--edit {
            margin-left: 15px;
        }

        &--download {
            margin-left: 0;
        }

    }
}
