.V3 .theme-repairer .site-sidebar {
    background: #131923;
}

.V3 .theme-repairer .site-sidebar .sidebar-menu>li.active>a,
.V3 .V3.compact-sidebar .theme-repairer .site-sidebar .sidebar-menu li.active>a,
.V3 .V3.compact-sidebar .theme-repairer .site-sidebar .sidebar-menu li:hover>a {
    background: none;
    background-color: #131923;
}

.V3 .theme-repairer .site-sidebar .sidebar-menu li > a:hover
{
    color: #79B1BB;
}
.V3 .theme-repairer .site-sidebar .sidebar-menu li.active > a {
    color: #79B1BB;
}
.V3 .theme-repairer .site-sidebar .sidebar-menu li.active > a > span > i {
    color: #79B1BB;
}

.V3 .theme-repairer .site-sidebar .sidebar-menu > li > a:hover .s-icon i {
    color: #79B1BB;
}

.V3.compact-sidebar .theme-repairer .site-sidebar .sidebar-menu li a:hover {
    border: 1px solid #79B1BB;
}