/*
  PageHeader and
  Breadcrumbs
   */
.V3 .page-header {
    min-height: 75px;
    padding: 14px 0 14px 15px;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(27, 30, 56, 0.25);
    background-color: #FFFFFF;
    font-size: 18px;
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    z-index: 1010;
}

.V3 .page-header h4 {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    color: #1C1F39;
}

@media (max-width: 425px) {
    .V3 .page-header h4 {
        margin-right: 30px;
        height: auto;
    }
}

.V3 .page-header .breadcrumbs {
    list-style: none;
    border-radius: 0;
    padding: 0px 0px 15px 0px;
    margin-bottom: 0px !important;
    background-color: white;
}

.V3 .page-header .breadcrumbs .breadcrumb-item {
    height: 18px;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    color: #1C1F39;
    float: left;
    padding-left: 0px;
}

.V3 .page-header .breadcrumbs .breadcrumb-item:hover a,
.V3 .page-header .breadcrumbs .breadcrumb-item:hover span{
    color: #14a3aa
}

.V3 .page-header .breadcrumbs .breadcrumb-item.active:hover a,
.V3 .page-header .breadcrumbs .breadcrumb-item.active:hover span{
    color: #1C1F39;
}

.V3 .page-header .breadcrumbs .breadcrumb-item.active {
    opacity: 0.5;
}

@media (max-width: 425px) {
    .V3 .page-header .breadcrumbs li.breadcrumb-item.mobile-path-hide {
        display: none;
    }

    .V3 .page-header h4 .autosave {
        display: block;
        position: absolute;
        right: 15px;
        top: 15px;
    }
}

.V3 .page-header .breadcrumbs .breadcrumb-item + .breadcrumb-item::before {
    content: "//";
    color: #1C1F39;
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
}

.V3 .page-header .breadcrumbs .breadcrumb-item a {
    color: #1C1F39;
    font-weight: 600;
}

@media (min-width: 1024.5px) {
    .V3 .cards-edit-page .page-header .page-header-nav-btn,
    .V3 .estimates-edit-page .page-header .page-header-nav-btn,
    .V3 .cards-edit-page .page-header .page-header-nav-pin-images,
    .V3 .estimates-edit-page .page-header .page-header-nav-pin-images,
    .V3 .cards-edit-page .page-header .page-header-nav-options,
    .V3 .estimates-edit-page .page-header .page-header-nav-options {
        display: none !important;
    }
}

@media (min-width: 768.5px) {
    .V3 .page-header .page-header-nav-btn,
    .V3 .page-header .page-header-nav-pin-images,
    .V3 .page-header .page-header-nav-options {
        display: none;
    }

    .V3 .cards-edit-page .page-header .page-header-nav-btn,
    .V3 .estimates-edit-page .page-header .page-header-nav-btn,
    .V3 .cards-edit-page .page-header .page-header-nav-pin-images,
    .V3 .estimates-edit-page .page-header .page-header-nav-pin-images,
    .V3 .cards-edit-page .page-header .page-header-nav-options,
    .V3 .estimates-edit-page .page-header .page-header-nav-options {
        display: inherit;
    }

    .V3 .estimates-edit-page .page-header .page-header-nav-options,
    .V3 .cards-edit-page .page-header .page-header-nav-options {
        position: absolute;
        right: 15px;
        bottom: 15px;
        z-index: 11111;
    }

    .V3 .estimates-edit-page .page-header .page-header-nav-pin-images,
    .V3 .cards-edit-page .page-header .page-header-nav-pin-images {
        position: absolute;
        right: 115px;
        bottom: 15px;
        z-index: 11111;
    }

    .V3 .estimates-edit-page .page-header .page-header-nav-pin-images label span.text,
    .V3 .cards-edit-page .page-header .page-header-nav-pin-images label span.text {
        width: 110px;
    }
}

@media (max-width: 768px) {
    .V3 .page-header .page-header-nav-btn {
        display: block;
        position: absolute;
        bottom: 15px;
        right: 15px;
    }

    .V3 .page-header .page-header-nav-options {
        position: absolute;
        right: 15px;
        bottom: -50px;
        z-index: 11111;
    }

    .V3 .page-header .page-header-nav-pin-images {
        position: absolute;
        right: 115px;
        bottom: 15px;
        z-index: 11111;
    }

    .V3 .page-header .page-header-nav-pin-images label span.text {
        width: 110px;
    }


}

@media (max-width: 425px) {
    .V3 .page-header .page-header-nav-pin-images {
        display: none;
    }

    .V3 .page-header .page-header-nav-options {
        bottom: -95px;
    }
}
