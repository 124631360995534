.invite-repairer .container p {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.invite-repairer .form {
  text-align: left;
  display: block;
  height: auto;
  margin: 0 auto;
}

.invite-repairer .form h1 {
  font-size: 32px;
  line-height: 43px;
  font-weight: bold;
}

.invite-repairer a {
  font-size: 16px;
  line-height: 26px;
  color: #29BBC1;
}

.invite-repairer {
  min-height: 100vh;
  height: auto;
}

.invite-repairer-form {
  padding-bottom: 15px;
  margin-bottom: 50px;
}

.invite-repairer-form .big-icon i {
  width: 75px;
  height: 75px;  
  font-size: 75px;
  margin: 0;
}

.invite-repairer .container {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 107px 1fr;
}

.V3 body.V3 .form .multiselect__single {
  font-size: 16px!important;
  font-weight: 600;
}