
#nprogress .bar {
    z-index: 111110 !important;
}

#nprogress .spinner {
    z-index: 111110 !important;
}

/* =================================================================
  Extra
================================================================= */

/* Loading progress */
#nprogress .spinner {
    width: 140px;
    right: auto;
    left: 50%;
    top: 20px;
    /*margin-left: -73px;*/
    /*padding: 8px 15px;*/
    background: #FFFFFF;
    color: #333333;
    border: 1px solid rgba(0, 0, 0, 0.125);

    margin-left: 5px;
    padding: 10px;
    border-radius: 5px;

}

#nprogress .spinner:after {
    content: "Processing...";
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 48px;
}

#nprogress .bar {
    height: 5px;
    transition-delay: 200ms;
    background: var(--supplier-color);
}

.theme-repairer ~ #nprogress .bar {
    background: var(--repairer-color);
}
