<template>
  <div>
    <h4 style="line-height: 1.5">Services \ Bookkeeping \ Backstaff
    </h4>
    <!--    <div class="company-logo"></div>-->
    <!--    <div class="company-info">-->
    <!--      Description of the business goes in here.-->
    <!--    </div>-->
    <iframe
        src="https://20738444.hs-sites.com/backstaff-bookkeeper-pricing-0"
        frameborder="0"
        id="marketplace-backstaff"
        class="iframe-wrapper"
        style="margin-top: 20px"
    ></iframe>
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "services-products",
  props: {
  },
})
</script>
<style scoped>
.hidden-el {
  visibility: hidden !important;
  height: 1px !important;
  width: 1px !important;
  position: absolute;
  top: -2000px;
}
.visible-el {
  visibility: visible !important;
}
.iframe-wrapper {
  width: 100%;
  /*height: calc(100vh - 700px);*/
  height: calc(100vh - 150px);
}

.company-logo{
  background-image: url(../../../assets/logos/marketplace-paint-office-tech-logo.png);
  height: 150px;
  width: 400px;
  background-size: contain;
  background-repeat: no-repeat;
}
.company-info{
  margin-top: 20px;
  background-color: #f0f0f0;
  border: 1px solid rgba(27, 30, 56, 0.2);
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 13px;
}
input.form-control.search-input {
  padding-left: 10px !important;
  padding-right: 35px;
  height: 32px;
  width: 250px;
  background: url('../../../assets/img/search-icon.svg') no-repeat center right;
}
.company-production{
  margin-top: 20px;
}
.company-production-body{
  flex-wrap: wrap;
}
.company-production-body div{
  width: 25%;
}
.company-production-body div iframe{
  margin: 10px 20px;
  height: 250px;
  border: 2px solid #d0cece;
  border-radius: 3px;
  background-color: #eeeeee;
}
@media screen and (max-width: 1730px) {
  .company-production-body div{
    width: 33.333%;
  }
}
@media screen and (max-width: 1400px) {
  .company-production-body div{
    width: 50%;
  }
}
@media screen and (max-width: 1100px) {
  .company-production-body div{
    width: 100%;
    text-align: center;
  }
}
</style>
