.theme-repairer .btn-primary {
    border-color: #79B1BB;
    font-weight: bold;
    border-radius: 3px;
    background-color: #79B1BB;
    color: #fff;
}

.theme-repairer .btn-primary:hover {
    background-color: #79B1BB;
    border-color: #79B1BB;
    color: #fff;
}

.theme-repairer .btn-primary.green-order,
.theme-repairer .btn-primary.green-order:hover,
.theme-repairer .btn-primary.green-order:focus,
.theme-repairer .btn-primary.green-order.focus,
.theme-repairer .btn-primary.green-order:active,
.theme-repairer .btn-primary.green-order.active {
    background-color: #17ae58;
    border-color: #17ae58;
}

.theme-repairer .btn-primary:focus,
.theme-repairer .btn-primary.focus {
    background-color: #79B1BB;
    border-color: #79B1BB;
}

.theme-repairer .btn-primary:active,
.theme-repairer .btn-primary.active,
.theme-repairer .open > .btn-primary.dropdown-toggle {
    background-color: #79B1BB;
    border-color: #79B1BB;
}

.theme-repairer .btn-primary:active:hover,
.theme-repairer .btn-primary:active:focus,
.theme-repairer .btn-primary:active.focus,
.theme-repairer .btn-primary.active:hover,
.theme-repairer .btn-primary.active:focus,
.theme-repairer .btn-primary.active.focus,
.theme-repairer .open > .btn-primary.dropdown-toggle:hover,
.theme-repairer .open > .btn-primary.dropdown-toggle:focus,
.theme-repairer .open > .btn-primary.dropdown-toggle.focus {
    background-color: #79B1BB;
    border-color: #79B1BB;
}

.theme-repairer .btn-primary.disabled:focus,
.theme-repairer .btn-primary.disabled.focus,
.theme-repairer .btn-primary:disabled:focus,
.theme-repairer .btn-primary:disabled.focus {
    background-color: #467EE1;
    border-color: #467EE1;
}

.theme-repairer .btn-primary.disabled:hover,
.theme-repairer .btn-primary:disabled:hover {
    background-color: #467EE1;
    border-color: #467EE1;
}

.theme-repairer .dropdown-options {
    box-sizing: border-box;
    height: 39px;
    width: 149px;
    border: none;
    border-radius: 3px;
}

.theme-repairer  .settings-page .edit-btn {
    height: 31px;
    width: 101px;
    border: 1px solid #79B1BB;
    color: #79B1BB;
}

.theme-repairer .settings-page .update-btn {
    width: 100px;
    height: 31px;
    border: none;
    background-color: #79B1BB !important;
    color: #ffffff;
}

.theme-repairer .settings-page .bx-cloud-upload {
    font-size: 37px;
    color: #79B1BB;
}

.theme-repairer .settings-page .white-save-btn {
    height: 38px;
    width: 83px;
    font-size: 13px;
    font-weight: bold;
    color: #79B1BB;
    border: 1px solid #79B1BB;
    border-radius: 3px;
    background: none;
    margin-right: 10px;
}

.theme-repairer
.settings-page
.custom-control-input:checked
~ .custom-control-label::before {
    width: 39px;
    height: 23px;
    border-radius: 11.5px;
    border-color: #79B1BB;
    background-color: #79B1BB;
}

.theme-repairer .settings-page .white-save-btn:hover {
    color: white;
    background-color: #79B1BB;
    border-color: #79B1BB;
}

.theme-repairer .settings-page .white-save-btn:active {
    color: white;
    background-color: #79B1BB;
    border-color: #79B1BB;
}

.theme-repairer .settings-page .add-location-btn {
    height: 38px;
    width: 126px;
    border-radius: 3px;
    background-color: #79B1BB;
    font-size: 13px;
    font-weight: bold;
    border: none;
    color: #ffffff;
}

.theme-repairer .settings-page .primary-save-btn {
    height: 38px;
    width: 83px;
    font-size: 13px;
    font-weight: bold;
    color: white;
    border: 1px solid #79B1BB;
    border-radius: 3px;
    margin-right: 10px;
}

.theme-repairer .dropdown-options .btn {
    color: #1C1F39;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    border-color:#79B1BB;
    background-color: #FFFFFF;

}
.theme-repairer .dropdown-options .btn:focus,
.theme-repairer .dropdown-options .btn:active:focus{
    box-shadow: none;
}
.theme-repairer .dropdown-options .btn:active{
    border-color:#79B1BB;
    color: #1C1F39;
    background:none;
}

.theme-repairer .dropdown-options ul.dropdown-menu{
    padding: 0px;
}

.theme-repairer .dropdown-options .dropdown-menu .dropdown-item  {
    height: 46px;
    color: #1C1F39;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    padding:15px 5px 15px 15px;
}

.theme-repairer .dropdown-options .dropdown-menu .dropdown-item:hover{
    background-color: rgba(41, 187, 193, 0.1);
    color: #192627;
}

.theme-repairer .dropdown-options .dropdown-arrow {
    box-sizing: border-box;
    width: 35px;
    border-left: 1px solid #79B1BB;
    display: block;
    position: absolute;
    top: 0px;
    height: 37px;
    right: 10px;
}
.theme-repairer .dropdown-options .dropdown-arrow .bx-down-arrow {
    font-size: 16px;
    margin: 11px 10px 10px 13px
}
.theme-repairer .dropdown-options.show .dropdown-arrow .bx-down-arrow {
    transform: rotate(0.5turn);
}

.theme-repairer .btn-outline-primary {
    border-color: #79B1BB;
    font-weight: bold;
    border-radius: 3px;
    color: #1C1F39;
}

.theme-repairer .btn-outline-primary:hover {
    background-color: rgba(41,187,193,.1);
    border-color: #79B1BB;
    color: #79B1BB;
}
.theme-repairer .btn-outline-primary.active:hover{
    background-color: #79B1BB;
}

.theme-repairer .btn-outline-primary:focus,
.theme-repairer .btn-outline-primary.focus,
.theme-repairer .btn-outline-primary:active,
.theme-repairer .btn-outline-primary.active,
.theme-repairer .btn-outline-primary:not(:disabled):not(.disabled):active,
.theme-repairer .btn-outline-primary:not(:disabled):not(.disabled).active {
    background-color: #79B1BB;
    border-color: #79B1BB;
    color: #fff;
    box-shadow: none;
}

.theme-repairer .btn-outline-primary:focus.active-ordered-button,
.theme-repairer .btn-outline-primary.focus.active-ordered-button,
.theme-repairer .btn-outline-primary:active.active-ordered-button,
.theme-repairer .btn-outline-primary.active.active-ordered-button,
.theme-repairer .btn-outline-primary:not(:disabled):not(.disabled):active.active-ordered-button,
.theme-repairer .btn-outline-primary:not(:disabled):not(.disabled).active.active-ordered-button {
    background-color: #17ae58;
    border-color: #17ae58;
    color: #fff;
    box-shadow: none;
}

.theme-repairer .button-back {
    border-color: #79B1BB;
    color: #79B1BB;
}

.theme-repairer .button-back:hover {
    background-color: #ffebde;
}

.theme-repairer .button-cancel {
    border-color: rgba(28,31,57,0.25);
    color: #1C1F39;
}

button.btn-animation{
    position: relative;
    border-color: #29BBC1;
    font-weight: bold;
    border-radius: 3px;
    background-color: #29BBC1;
    flex-shrink: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0.75rem 1rem;
    color: #fff;
    text-align: center;
    border: 1px solid transparent;
    cursor: pointer;
}

button.btn-animation:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, .5);
    opacity: 0;
    border-radius: 100%;
    -webkit-transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
}


@keyframes ripple {
    0% {
        -webkit-transform: scale(0, 0);
        opacity: 1;
    }
    20% {
        -webkit-transform: scale(25, 25);
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(40, 40);
    }
}

button.btn-animation:focus:not(:active)::after {
    -webkit-animation: ripple 1s ease-out;
}
