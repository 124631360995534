
.disabled-button {
  opacity: 0.7 !important;
}

.disabled-button:hover {
  background-color: #bdbdbd4d;
}
.credit-view {
  &__row {
    &--xl {
      width: 20%;
      min-width: 300px;
    }

    &--lg {
      width: 10%;
      min-width: 150px;
    }

    &--md {
      width: 7%;
      min-width: 150px;
    }

    &--sm {
      width: 5%;
      min-width: 110px;
    }

    &--xs {
      width: 3%;
      min-width: 80px;
    }
  }

  &__context-menu {
    .btn {
      border: none;
      padding: 15px 15px 11px 15px;
      transition-duration: 150ms;
    }

    .theme-repairer & .btn:hover {
      color: #070707;
    }

    .theme-supplier & .btn:hover {
      color: #29BBC1;
    }
  }
}

.credit-view-page {
  // right click menu container
  .v-context {
    width: auto !important;
    border: none;
    box-shadow: rgba(27, 30, 56, 0.15);
  }

  .table.b-table > tbody > .table-active,
  .table.b-table > tbody > .table-active > th,
  .table.b-table > tbody > .table-active > td {
    background-color: rgba(46, 169, 255, 0.1);
  }

  .table.b-table > thead > tr > [aria-sort="none"],
  .table.b-table > thead > tr > [aria-sort="descending"],
  .table.b-table > thead > tr > [aria-sort="ascending"],
  .table [role='columnheader'][aria-label='Status Id'] {
    padding: 15px 15px 17px 10px !important;
    background-color: #EFEFF1 !important;
    top: -1px;
    border: 0;
    box-sizing: border-box;
  }

  .b-table-sticky-header {
    max-height: 60vh;
    border-bottom: 1px solid rgb(175, 177, 179);
  }

  .dropdown-item {
    padding: 13px 4px 15px 15px !important;
  }

  // custom dot on buildings icon
  .bx-buildings {
    color: #1B1E38;
    position: relative;

    &:after {
      content: '';
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDJDNi40ODYgMiAyIDYuNDg2IDIgMTJDMiAxNy41MTQgNi40ODYgMjIgMTIgMjJDMTcuNTE0IDIyIDIyIDE3LjUxNCAyMiAxMkMyMiA2LjQ4NiAxNy41MTQgMiAxMiAyWk0xMC4wMDEgMTYuNDEzTDYuMjg4IDEyLjcwOEw3LjcgMTEuMjkyTDkuOTk5IDEzLjU4N0wxNS4yOTMgOC4yOTNMMTYuNzA3IDkuNzA3TDEwLjAwMSAxNi40MTNaIiBmaWxsPSIjMkRDQTczIi8+Cjwvc3ZnPgo=");
      background-repeat: no-repeat;
      background-size: contain;
      width: 7px;
      height: 7px;
      position: absolute;
      background-color: white;
      border-radius: 50%;
      top: 0;
      right: 0;
    }

    &--no-dot:after {
      display: none;
    }
  }

  @media screen and (max-width: 991px) {
    .b-table-sticky-header {
      max-height: 50vh;
    }
  }

  /* overwrite */
  [role='cell'] .text-primary,
  .bx.text-primary {
    color: #63BCFF !important
  }

  // table modifications
  // to make border works on sticky column (firefox)
  .table {
    border-collapse: separate;
    border-spacing: 0;
  }

  // to fix blue color on selected row,
  // when scrolled horizontally, so it is not transparent
  .b-table-row-selected.table-active .b-table-sticky-column {
    background-color: #D6EEFF !important;
  }

  .b-table-row-selected.table-active + .b-table-details {
    background-color: #D6EEFF !important;
  }

  // row border
  .table.b-table td {
    border-color: rgba(27, 30, 56, 0.1);
    vertical-align: top;
  }

  // dont use different shade on freeze column
  .table.b-table.table-hover > tbody > tr:hover:not(.b-table-row-selected) > .table-b-table-default {
    background: inherit
  }

  // horizontal scrollbar
  .table-responsive {
    &::-webkit-scrollbar {
      width: 7px
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b7b8c0;
    }

    scrollbar-width: thin;
    scrollbar-color: #b7b8c0;
  }
}

.credit-title {
  margin: 15px 0 12px 0;

  &__item {
    min-width: 360px;
    font-size: 13px;
    margin-bottom: 5px;
  }
}

.ps-label-value__label {
  display: inline-block;
  width: 118px;
  white-space: nowrap;
  color: #a0a1a7;
  font-weight: 700;
}

.ps-label-value__value {
  font-weight: 700;
}

.credit-footer {
  //padding-left: 20%;
  &__whitespace {
    display: inline-block;
    width: 19%;
    min-width: 230px;
  }

  &__eta-label,
  &__eta-date {
    opacity: 0.5;
  }

  &__eta-date {
    margin-left: 50px;
  }

  @media screen and (max-width: 991px) {
    &__whitespace {
      width: 20%;
      min-width: 155px;
    }
  }
}

/* table column width */
.credit-th {
  &--part-list {
    min-width: 200px;
    width: 15%;
  }

  @media screen and (max-width: 991px) {
    &--part-list {
      min-width: 130px;
    }
  }
}

/* show details */
.credit-view-page tr.show-detail.b-table-row-selected {
  background-color: white !important;

  td,
  td.b-table-sticky-column {
    background-color: white !important;
  }
}

@media (max-width: 1245px) {
  .credit-view-page .credit-additional-block {
    display: block !important;
  }
}

@media (max-width: 764px) {
  .credit-view-page .credit-additional-block:not(:first-child) {
    display: none !important;
  }

  .credit-view-page .credit-additional-block .credit-title {
    max-width: 100%;
  }
}

.credit-view-page .credit-view-body .tabs-component {
  border-bottom: 10px solid #f1f1f7;
}
