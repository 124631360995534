.V3 body.V3 .multiselect__select {
    top: 4px;
    right: -1px;
}

.V3 body.V3 .multiselect__select:before {
    font-size: 23px;
}

.V3 body.V3 .multiselect--active .multiselect__select {
    top: 8px;
}

.V3 select.form-control{
    display: block;
    padding-right: 70px !important;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAArElEQVRIS+2T0Q3CMAxEXzdgk3YEmIgRSjfoRjACbMII6CSQQhrHzkclkOKvNrresy/uwM417OxPB7gJ94h+P6ILsAJPo9UDcAYWa5TaJct8Bu7AqQCR+RWY3gDpN1UDyOAGjAVIav4AjtaU3pqWIOry03nVXEIPIE0O0Zlicc2jgByi95B5CyCF6NnMPL/lSETpN4pLZa1t0xa5f2lE0DpBxPNL0wFuZP8f0Qs+eB0Z+AOfuQAAAABJRU5ErkJggg==") right center no-repeat;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
/* body select.select_box option */
.V3 select.form-control option {
    padding: 4px;
    line-height: 1.5;
}
/* for IE and Edge */
.V3 select.form-control::-ms-expand {
    display: none;
}
.V3 select.form-control:disabled::-ms-expand {
    background: #f60;
}
.multiselect__spinner {
    position: absolute;
    right: 1px;
    top: 1px;
    width: 48px;
    height: 31px;
    background: #FFFFFF;
    display: block;
    margin-right: 2px;
}

.multiselect__spinner:after, .multiselect__spinner:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border-color: #41B883 transparent transparent;
    border-style: solid;
    border-width: 2px;
    box-shadow: 0 0 0 1px transparent
}

.multiselect__spinner:before {
    animation: a 2.4s cubic-bezier(.41, .26, .2, .62);
    animation-iteration-count: infinite
}

.multiselect__spinner:after {
    animation: a 2.4s cubic-bezier(.51, .09, .21, .8);
    animation-iteration-count: infinite
}

.multiselect__loading-enter-active, .multiselect__loading-leave-active {
    transition: opacity .4s ease-in-out;
    opacity: 1
}

.multiselect__loading-enter, .multiselect__loading-leave-active {
    opacity: 0
}

.multiselect, .multiselect__input, .multiselect__single {
    font-family: inherit;
    -ms-touch-action: manipulation;
    touch-action: manipulation
}

.multiselect {
    box-sizing: content-box;
    display: block;
    position: relative;
    width: 100%;
    text-align: left;
    color: #35495E
}

.multiselect * {
    box-sizing: border-box
}

.multiselect:focus {
    outline: none
}

.multiselect--disabled {
    opacity: .6
}

.multiselect--active {
    z-index: 1
}

.multiselect--active:not(.multiselect--above) .multiselect__current, .multiselect--active:not(.multiselect--above) .multiselect__input, .multiselect--active:not(.multiselect--above) .multiselect__tags {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.multiselect--active .multiselect__select {
    transform: rotate(180deg)
}

.multiselect--above.multiselect--active .multiselect__current, .multiselect--above.multiselect--active .multiselect__input, .multiselect--above.multiselect--active .multiselect__tags {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.multiselect__input, .multiselect__single {
    position: relative;
    display: inline-block;
    min-height: 20px;
    line-height: 20px;
    border: none;
    border-radius: 5px;
    background: #FFFFFF;
    /*padding: 0 0 0 5px;*/
    width: 100%;
    transition: border .1s ease;
    box-sizing: border-box;
    /*  margin-bottom: 8px;*/
    vertical-align: top
}

.multiselect__input::-webkit-input-placeholder {
    color: #35495E
}

.multiselect__input:-ms-input-placeholder {
    color: #35495E
}

.multiselect__input::placeholder {
    color: #35495E
}

.multiselect__tag ~ .multiselect__input, .multiselect__tag ~ .multiselect__single {
    width: auto
}

.multiselect__input:hover, .multiselect__single:hover {
    border-color: #CFCFCF
}

.multiselect__input:focus, .multiselect__single:focus {
    border-color: #A8A8A8;
    outline: none
}

.multiselect__single {
    /*padding-left: 5px;*/
    /*margin-bottom: 8px*/
}

.multiselect__tags-wrap {
    display: inline
}

.multiselect__tags {
    min-height: 20px;
    display: block;
    padding: .5rem 40px .5rem .75rem;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .25);
    background: #FFFFFF;
    height: 32px;
}

.multiselect__tag {
    position: relative;
    display: inline-block;
    padding: 4px 26px 4px 10px;
    border-radius: 5px;
    margin-right: 10px;
    color: #FFFFFF;
    line-height: 1;
    background: #41B883;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis
}

.multiselect__tag-icon {
    cursor: pointer;
    margin-left: 7px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 22px;
    text-align: center;
    line-height: 22px;
    transition: all .2s ease;
    border-radius: 5px
}

.multiselect__tag-icon:after {
    content: "\D7";
    color: #266D4D;
}

.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
    background: #369A6E
}

.multiselect__tag-icon:focus:after, .multiselect__tag-icon:hover:after {
    color: #FFFFFF
}

.multiselect__current {
    min-height: 20px;
    overflow: hidden;
    padding: 8px 12px 0;
    padding-right: 30px;
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #E8E8E8
}

.multiselect__current, .multiselect__select {
    line-height: 16px;
    box-sizing: border-box;
    display: block;
    margin: 0;
    text-decoration: none;
    cursor: pointer
}

.multiselect__select {
    position: absolute;
    right: 1px;
    width: 40px;
    height: 22px;
    top: 8px;
    padding: 4px 8px;
    text-align: center;
    transition: transform .2s ease;
    display: inline-block;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

.multiselect__select:before {
    position: relative;
    right: 0;
    margin-top: 4px;
}

.multiselect__placeholder {
    color: #ADADAD;
    display: inline-block;
    /* margin-bottom: 10px;*/
    /*padding-top: 2px*/
}

.multiselect--active .multiselect__placeholder {
    display: none
}

.multiselect__content-wrapper {
    position: absolute;
    display: block;
    background: #FFFFFF;
    width: 100%;
    max-height: 240px;
    overflow: auto;
    border: 1px solid #E8E8E8;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 1;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    scrollbar-width: thin;
}

.multiselect__content {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    min-width: 100%;
    vertical-align: top;
}

.multiselect--above .multiselect__content-wrapper {
    bottom: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
    border-top: 1px solid #E8E8E8
}

.multiselect__content::webkit-scrollbar {
    display: none
}

.multiselect__element {
    display: block
}

.multiselect__option {
    display: block;
    padding: .5rem .75rem;;
    min-height: 20px;
    line-height: 16px;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    font-weight: 600;
}

.multiselect__option:after {
    top: 0;
    right: 0;
    position: absolute;
    line-height: 40px;
    padding-right: 12px;
    padding-left: 20px;
}

.multiselect__option--highlight {
    /*background: #41B883;*/
    outline: none;
    /*color: #FFFFFF*/
    background-color: #F5F5F5 !important;
    color: #5E6571;
}

.theme-repairer .multiselect__option--highlight {
    /*background: #41B883;*/
    outline: none;
    /*color: #FFFFFF*/
    background-color: rgba(41, 187, 193, 0.1) !important;
    color: #070707;
    border-bottom: 1px solid #DCDCDC;
    font-weight: bold;
}

.multiselect__option--highlight:after {
    content: attr(data-select);
    /*background: #41B883;*/
    /*color: #FFFFFF*/
    background-color: #F5F5F5 !important;
    color: #5E6571;
}

.multiselect__option--selected {
    background-color: rgba(41, 187, 193, 0.1) !important;
    color: #070707;
    font-weight: 600
}

.multiselect__option--selected:after {
    content: attr(data-selected);
    color: silver
}

.multiselect__option--selected.multiselect__option--highlight {
    background: #F3F3F3;
    color: #070707;
    /*  color: #FFFFFF*/
}

.multiselect__option--selected.multiselect__option--highlight:after {
    background: #F3F3F3;
    color: #35495E;
    content: attr(data-deselect);
    /* color: #FFFFFF*/
}

.multiselect--disabled {
    /*background: #EDEDED;*/
    background:#fff;
    pointer-events: none
}

.multiselect--disabled .multiselect__current, .multiselect--disabled .multiselect__select, .multiselect__option--disabled {
    /*background: #EDEDED;*/
    background:#fff;
    color: #A6A6A6;
}

.V3 .multiselect--disabled .multiselect__current,
.V3 .multiselect--disabled .multiselect__select,
.V3 .multiselect__option--disabled {
    background:none;
    color: #A6A6A6;
}

.multiselect__option--disabled {
    cursor: text;
    pointer-events: none
}

.multiselect__option--group {
    background: #EDEDED;
    color: #35495E
}

.multiselect__option--group.multiselect__option--highlight {
    background: #35495E;
    color: #FFFFFF
}

.multiselect__option--group.multiselect__option--highlight:after {
    background: #35495E;
}

.multiselect__option--disabled.multiselect__option--highlight {
    background: #DEDEDE
}

.multiselect__option--group-selected.multiselect__option--highlight {
    background: #FF6A6A;
    color: #FFFFFF
}

.multiselect__option--group-selected.multiselect__option--highlight:after {
    background: #FF6A6A;
    content: attr(data-deselect);
    color: #FFFFFF
}

.multiselect-enter-active, .multiselect-leave-active {
    transition: all .15s ease
}

.multiselect-enter, .multiselect-leave-active {
    opacity: 0
}

.multiselect__strong {
    margin-bottom: 8px;
    line-height: 20px;
    display: inline-block;
    vertical-align: top
}

@keyframes a {
    0% {
        transform: rotate(0)
    }
    to {
        transform: rotate(2turn)
    }
}

.multiselect__select {
    display: inline-block;
    font-family: "boxicons";
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

.multiselect__select:before {
    content: "\ece8";
    border: none;
}

.V3 .multiselect__content-wrapper {
    border: 1px solid rgba(28, 31, 57, 0.25);
}

.V3 .multiselect__input, .V3 .multiselect__single, .V3 .multiselect__tags {
    min-height: 17px;
    line-height: 16px;
    font-size: 12px;
    font-weight: 600;
}
