/* =================================================================
  Header
================================================================= */

.V3 .site-header {
    z-index: 10001;
}

.fixed-header .site-header {
    z-index: 100;
}

.fixed-header .site-header {
    position: fixed;
    top: 0;
    left: 240px;
    right: 0;
}

.site-header .navbar {
    padding: 0;
    background-color: #FFFFFF;
}

.V3 .site-header .navbar {
    border-bottom: 1px solid rgba(27, 30, 56, 0.25);
    height: 60px;
    margin-bottom:-1px;
}

.site-header .navbar-left {
    height: 60px;
    background-color: #212330;
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

@media (min-width: 768px) {
    .site-header .navbar-left {
        float: left;
        width: 240px;
    }

    .compact-sidebar .site-header .navbar-left {
        width: 70px;
    }
}

.site-header .navbar-brand {
    display: inline-block;
    float: none;
    margin: 0;
    padding: 1.3rem 0 0;
    height: 60px;
}

.site-header .navbar-brand .logo {
    width: 100px;
    height: 25px;
    background: url(~@/assets/logos/logo.svg) no-repeat;
}


.site-header .navbar-right {
    padding: 0 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
    .site-header .navbar-right {
        /*   margin-left: 220px;*/
        height: 60px !important;
    }

    .compact-sidebar .site-header .navbar-right {
        margin-left: 70px;
    }
}

.site-header .toggle-button {
    position: relative;
    width: 55px;
    height: 60px;
    line-height: 60px;
    font-size: 1.5rem;
    cursor: pointer;
}

.site-header .navbar-right .toggle-button {
    margin-left: -15px;
}

.site-header .toggle-button.light {
    color: rgba(0, 0, 0, 0.5);
}

.site-header .toggle-button.dark {
    color: rgba(255, 255, 255, 0.5);
}

.site-header .toggle-button span {
    position: absolute;
    display: block;
    top: 50%;
    right: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.site-header .toggle-button span.hamburger {
    left: 15px;
    margin-top: -1px;
    width: 22px;
    height: 2px;
}

.site-header .toggle-button span.more {
    left: 50%;
    margin: -2px 0 0 -2px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
}

.site-header .toggle-button.light span {
    background-color: rgba(0, 0, 0, 0.5);
}

.site-header .toggle-button.dark span {
    background-color: rgba(255, 255, 255, 0.5);
}

.site-header .toggle-button span:before,
.site-header .toggle-button span:after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.site-header .toggle-button span.hamburger:before,
.site-header .toggle-button span.hamburger:after {
    height: 2px;
}

.site-header .toggle-button span.more:before,
.site-header .toggle-button span.more:after {
    height: 4px;
    border-radius: 50%;
}

.site-header .toggle-button.light span:before,
.site-header .toggle-button.light span:after {
    background-color: rgba(0, 0, 0, 0.5);
}

.site-header .toggle-button.dark span:before,
.site-header .toggle-button.dark span:after {
    background-color: rgba(255, 255, 255, 0.5);
}

.site-header .toggle-button span.hamburger:before {
    top: -6px;
}

.site-header .toggle-button span.hamburger:after {
    bottom: -6px;
}

.site-header .toggle-button span.more:before {
    top: -8px;
}

.site-header .toggle-button span.more:after {
    bottom: -8px;
}

.site-header .toggle-button.light:hover span,
.site-header .toggle-button.light:hover span:before,
.site-header .toggle-button.light:hover span:after,
.site-header .toggle-button.light:focus span,
.site-header .toggle-button.light:focus span:before,
.site-header .toggle-button.light:focus span:after {
    background-color: #333333;
}

.site-header .toggle-button.dark:hover span,
.site-header .toggle-button.dark:hover span:before,
.site-header .toggle-button.dark:hover span:after,
.site-header .toggle-button.dark:focus span,
.site-header .toggle-button.dark:focus span:before,
.site-header .toggle-button.dark:focus span:after {
    background-color: #FFFFFF;
}

.site-header .toggle-button-second {
    width: 45px;
    height: 59px;
    line-height: 60px;
    text-align: center;
    cursor: pointer;
}

.site-header .navbar-right .toggle-button-second {
    margin: 0 -15px 0 15px;
}

.site-header .toggle-button-second.light {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.5);
}

.site-header .toggle-button-second.dark {
    border-left: 1px solid rgba(255, 255, 255, 0.125);
    color: rgba(255, 255, 255, 0.7);
}

.site-header .toggle-button-second i {
    display: inline-block;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.site-header .toggle-button-second.active i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.site-header .navbar-nav > li > a,
.site-header .navbar-nav > li > span {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

@media (min-width: 768px) {
    .site-header .navbar-nav > li > a,
    .site-header .navbar-nav > li > span {
        padding-top: 0;
        padding-bottom: 0;
        line-height: 60px;
    }
}

.site-header .navbar-nav > li > a i {
    position: relative;
    top: 2px;
    font-size: 1.3rem;
}

.site-header .navbar-nav > li .btn {
    vertical-align: baseline;
}

.site-header .navbar-nav > li .avatar img {
    vertical-align: top;
}

.site-header .navbar .dropdown-menu {
    margin-top: -1px;
}

.site-header .dropdown-item.active,
.site-header .dropdown-item:active {
    background-color: var(--supplier-color);
}

.theme-repairer .site-header .dropdown-item.active,
.theme-repairer .site-header .dropdown-item:active {
    background-color: var(--repairer-color);
}

@media (max-width: 767px) {
    .site-header .navbar .navbar-toggleable-sm {
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        padding: 1rem 2rem;
        background-color: #FFFFFF;
        border-bottom: 1px solid #DDDDDD;
    }
}

@media (max-width: 991px) {
    .site-header .navbar {
        margin-left: 0 !important;
    }
}

.site-header .header-form {
    padding-top: 1.1rem;
}

@media (max-width: 767px) {
    .site-header .header-form {
        padding: 0.75rem 0;
    }
}

.site-header .header-form form {
    position: relative;
}

.site-header .header-form .btn {
    position: absolute;
    top: 1px;
    right: 1px;
    color: #999999;
}

@media (min-width: 768px) {
    .site-header .header-form .form-control {
        width: 180px;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .site-header .header-form .form-control:focus {
        width: 300px;
    }
}

.site-header .dropdown-more {
    display: block;
    padding: 0.75rem 1rem;
    background-color: #EEEEEE;
    color: #999999;
    text-align: center;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.site-header .dropdown-more:hover,
.site-header .dropdown-more:focus {
    color: #333333;
}

/* Apps */
.site-header .dropdown-apps {
    padding: 0;
    min-width: 300px;
}

.site-header .dropdown-apps .a-grid {
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.site-header .dropdown-apps .a-item {
    text-align: center;
}

.site-header .dropdown-apps .a-item a {
    display: block;
    padding: 1rem 0;
    color: rgba(0, 0, 0, 0.5);
    border: 1px solid transparent;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.site-header .dropdown-apps .a-item a:hover,
.site-header .dropdown-apps .a-item a:focus {
    border-color: rgba(0, 0, 0, 0.125);
    color: rgba(0, 0, 0, 0.9);
}

.site-header .dropdown-apps .a-item .ai-icon {
    margin: 0 auto 1rem auto;
    width: 32px;
    height: 32px;
}

.site-header .dropdown-apps .a-item .ai-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Tasks */
.site-header .dropdown-tasks {
    padding: 0;
    min-width: 300px;
}

.site-header .dropdown-tasks .t-item {
    display: block;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.site-header .dropdown-tasks .t-item:last-child {
    border-bottom: none;
}

.site-header .dropdown-tasks a.t-item {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.site-header .dropdown-tasks .t-item .progress {
    margin-bottom: 0.5rem;
}

.site-header .dropdown-tasks .t-item .avatar {
    margin-right: 0.5rem;
}

/* Messages */
.site-header .dropdown-messages {
    padding: 0;
    min-width: 300px;
}

.site-header .dropdown-messages .m-item {
    display: block;
    position: relative;
    padding: 0.75rem 1rem 0.75rem 4.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.site-header .dropdown-messages .m-item:last-child {
    border-bottom: none;
}

.site-header .dropdown-messages .m-item {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.site-header .dropdown-messages .m-item .mi-icon {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 0.75rem;
    text-align: center;
    border-radius: 50%;
}

.site-header .dropdown-messages .m-item .mi-text {
    color: #999999;
}

.site-header .dropdown-messages .m-item .mi-time {
    font-size: 90%;
    color: #999999;
}