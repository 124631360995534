.V3 .notices {
    z-index: 100052;
    top: 27px;
    font-family: "Inter", Arial, Helvetica, sans-serif;
}

@keyframes fadeOut {
    from {
        opacity: 1
    }
    to {
        opacity: 0
    }
}

.fadeOut {
    animation-name: fadeOut
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0)
    }
    to {
        opacity: 1;
        transform: none
    }
}

.fadeInDown {
    animation-name: fadeInDown
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0)
    }
    to {
        opacity: 1;
        transform: none
    }
}

.fadeInUp {
    animation-name: fadeInUp
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 150ms ease-out
}

.fade-enter, .fade-leave-to {
    opacity: 0
}

.notices {
    position: fixed;
    display: flex;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2em;
    overflow: hidden;
    pointer-events: none
}

.notices .toast {
    flex-basis: auto;
    display: inline-flex;
    align-items: center;
    animation-duration: 150ms;
    margin: .5em 0;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    border-radius: .25em;
    pointer-events: auto;
    opacity: .92;
    color: #FFFFFF;
    min-height: 3em;
    cursor: pointer
}

.notices .toast .toast-text {
    margin: 0;
    padding: .5em 1em;
    word-break: break-word
}

.notices .toast .toast-icon {
    display: none
}

.notices .toast-success {
    background-color: #47D78A
}

.notices .toast-info {
    background-color: #1C85D5
}

.notices .toast-warning {
    background-color: #FEBC22
}

.notices .toast-error {
    background-color: #F7471C
}

.notices .toast-default {
    background-color: #343A40
}

.notices .toast.is-top, .notices .toast.is-bottom {
    align-self: center
}

.notices .toast.is-top-right, .notices .toast.is-bottom-right {
    align-self: flex-end
}

.notices .toast.is-top-left, .notices .toast.is-bottom-left {
    align-self: flex-start
}

.notices.is-top {
    flex-direction: column
}

.notices.is-bottom {
    flex-direction: column-reverse
}

.notices.is-custom-parent {
    position: absolute
}

@media screen and (max-width: 768px) {
    .notices {
        padding: 0;
        position: fixed !important
    }
}

.notices .toast {
    opacity: 1;
    min-height: 4em
}

.notices .toast .toast-text {
    padding: 1.5em 1em
}

.notices .toast .toast-icon {
    display: block;
    width: 27px;
    min-width: 27px;
    height: 27px;
    margin-left: 1em;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 45.999 45.999'%3E %3Cpath fill='%23fff' d='M39.264 6.736c-8.982-8.981-23.545-8.982-32.528 0-8.982 8.982-8.981 23.545 0 32.528 8.982 8.98 23.545 8.981 32.528 0 8.981-8.983 8.98-23.545 0-32.528zM25.999 33a3 3 0 11-6 0V21a3 3 0 116 0v12zm-3.053-17.128c-1.728 0-2.88-1.224-2.844-2.735-.036-1.584 1.116-2.771 2.879-2.771 1.764 0 2.88 1.188 2.917 2.771-.001 1.511-1.152 2.735-2.952 2.735z'/%3E %3C/svg%3E") no-repeat
}

.notices .toast.toast-success .toast-icon {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'%3E %3Cpath fill='%23fff' d='M26 0C11.664 0 0 11.663 0 26s11.664 26 26 26 26-11.663 26-26S40.336 0 26 0zm14.495 17.329l-16 18a1.997 1.997 0 01-2.745.233l-10-8a2 2 0 012.499-3.124l8.517 6.813L37.505 14.67a2.001 2.001 0 012.99 2.659z'/%3E %3C/svg%3E") no-repeat
}

.notices .toast.toast-error .toast-icon {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.976 51.976'%3E %3Cpath fill='%23fff' d='M44.373 7.603c-10.137-10.137-26.632-10.138-36.77 0-10.138 10.138-10.137 26.632 0 36.77s26.632 10.138 36.77 0c10.137-10.138 10.137-26.633 0-36.77zm-8.132 28.638a2 2 0 01-2.828 0l-7.425-7.425-7.778 7.778a2 2 0 11-2.828-2.828l7.778-7.778-7.425-7.425a2 2 0 112.828-2.828l7.425 7.425 7.071-7.071a2 2 0 112.828 2.828l-7.071 7.071 7.425 7.425a2 2 0 010 2.828z'/%3E %3C/svg%3E") no-repeat
}

.notices .toast.toast-warning .toast-icon {
    background: url("data:image/svg+xml,%3Csvg viewBox='0 0 52 52' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath fill='%23fff' d='M49.466 41.26L29.216 6.85c-.69-1.16-1.89-1.85-3.22-1.85-1.32 0-2.53.69-3.21 1.85L2.536 41.26c-.71 1.2-.72 2.64-.03 3.85.68 1.18 1.89 1.89 3.24 1.89h40.51c1.35 0 2.56-.71 3.23-1.89.7-1.21.69-2.65-.02-3.85zm-25.53-21.405h3.381v3.187l-.724 8.92H24.66l-.725-8.92v-3.187zm2.97 17.344a1.712 1.712 0 01-1.267.543c-.491 0-.914-.181-1.268-.543a1.788 1.788 0 01-.531-1.297c0-.502.176-.935.53-1.297a1.712 1.712 0 011.269-.544c.49 0 .914.181 1.268.544s.53.795.53 1.297c0 .503-.176.934-.53 1.297z'/%3E %3C/svg%3E") no-repeat
}
