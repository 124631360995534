.theme-supplier .btn-primary {
    border-color: #29BBC1;
    font-weight: bold;
    border-radius: 3px;
    background-color: #29BBC1;
}

.theme-supplier .update-btn{
    width: 100px;
    height: 31px;
    border: none;
    background-color: #29BBC1 !important;
    color: #ffffff;
}

.theme-supplier .settings-page .primary-save-btn {
    height: 38px;
    width: 83px;
    font-size: 13px;
    font-weight: bold;
    color: white;
    border-radius: 3px;
    margin-right: 10px;
}

.theme-supplier .btn-primary:hover {
    background-color: #29BBC1;
    border-color: #29BBC1;
}

.theme-supplier .settings-page .white-save-btn {
    height: 38px;
    width: 83px;
    font-size: 13px;
    font-weight: bold;
    color: #29BBC1;
    border: 1px solid #29BBC1;
    border-radius: 3px;
    background: none;
    margin-right: 10px;
}

.theme-supplier .settings-page .white-save-btn:hover {
    color: white;
    background-color: #29BBC1;
    border-color: #29BBC1;
}

.theme-supplier .settings-page .white-save-btn:active {
    color: white;
    background-color: #29BBC1;
    border-color: #29BBC1;
}

.theme-supplier .settings-page .add-location-btn {
    height: 38px;
    width: 126px;
    border-radius: 3px;
    background-color: #29BBC1;
    font-size: 13px;
    font-weight: bold;
    border: none;
    color: #ffffff;
}

.theme-supplier .settings-page .edit-btn {
    height: 31px;
    width: 101px;
    border: 1px solid #29BBC1;
    color: #29BBC1;
}

.theme-supplier
.settings-page
.custom-control-input:checked
~ .custom-control-label::before {
    width: 39px;
    height: 23px;
    border-radius: 11.5px;
    border-color: #29BBC1;
    background-color: #29BBC1;
}


.theme-supplier .settings-page .bx-cloud-upload {
    font-size: 37px;
    color: #29BBC1;
}

.theme-supplier .btn-primary:focus,
.theme-supplier .btn-primary.focus {
    background-color: #29BBC1;
    border-color: #29BBC1;
}

.theme-supplier .btn-primary:active,
.theme-supplier .btn-primary.active,
.theme-supplier .open>.btn-primary.dropdown-toggle {
    background-color: #29BBC1;
    border-color: #29BBC1;
}

.theme-supplier .btn-primary:active:hover,
.theme-supplier .btn-primary:active:focus,
.theme-supplier .btn-primary:active.focus,
.theme-supplier .btn-primary.active:hover,
.theme-supplier .btn-primary.active:focus,
.theme-supplier .btn-primary.active.focus,
.theme-supplier .open>.btn-primary.dropdown-toggle:hover,
.theme-supplier .open>.btn-primary.dropdown-toggle:focus,
.theme-supplier .open>.btn-primary.dropdown-toggle.focus {
    background-color: #29BBC1;
    border-color: #29BBC1;
}

.theme-supplier .btn-primary.disabled:focus,
.theme-supplier .btn-primary.disabled.focus,
.theme-supplier .btn-primary:disabled:focus,
.theme-supplier .btn-primary:disabled.focus {
    background-color: #467EE1;
    border-color: #467EE1;
}

.theme-supplier .btn-primary.disabled:hover,
.theme-supplier .btn-primary:disabled:hover {
    background-color: #467EE1;
    border-color: #467EE1;
}

.theme-supplier .dropdown-options {
    box-sizing: border-box;
    height: 39px;
    width: 149px;
    border: none;
    border-radius: 3px;
}

.theme-supplier .dropdown-options .btn {
    color: #1C1F39;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    border-color: #29BBC1;
    background-color: #FFFFFF;
    padding: 10.5px;
    display: flex;
    align-items: center;
}

.theme-supplier .dropdown-options .btn:focus,
.theme-supplier .dropdown-options .btn:active:focus {
    box-shadow: none;
}

.theme-supplier .dropdown-options .btn:active {
    border-color: #29BBC1;
    color: #1C1F39;
    background: none;
}

.theme-supplier .dropdown-options ul.dropdown-menu {
    padding: 0px;
    box-shadow: 0 2px 4px 0 rgba(27,30,56,0.15);
    border: 3px;
}


.theme-supplier .dropdown-options .dropdown-menu .dropdown-item {
    height: 46px;
    color: #1C1F39;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    padding: 15px 5px 15px 15px;
    overflow: auto;
}

.theme-supplier .dropdown-options .dropdown-menu li[role="presentation"]:hover {
    border-color: white;
}

.theme-supplier .dropdown-options .dropdown-menu .dropdown-item:hover {
    /* background-color: rgba(255, 127, 46, 0.1); */
    background-color: rgba(41,187,193,0.1);
    color: #070707;
}

.theme-supplier .dropdown-options .dropdown-arrow {
    box-sizing: border-box;
    width: 35px;
    border-left: 1px solid #29BBC1;
    display: block;
    position: absolute;
    top: 0px;
    height: 37px;
    right: 10px;
}

.theme-supplier .dropdown-options .dropdown-arrow .bx-down-arrow {
    font-size: 16px;
    margin: 11px 10px 10px 13px
}

.theme-supplier .dropdown-options.show .dropdown-arrow .bx-down-arrow {
    transform: rotate(0.5turn);
}

.theme-supplier .btn-outline-primary {
    border-color:  #29BBC1;;
    font-weight: bold;
    border-radius: 3px;
    color: #1C1F39;
}

.theme-supplier .btn-outline-primary:hover {
    background-color: #cff1f3;
    border-color:  #29BBC1;
}

.theme-supplier .btn-outline-primary:focus,
.theme-supplier .btn-outline-primary.focus,
.theme-supplier .btn-outline-primary:active,
.theme-supplier .btn-outline-primary.active,
.theme-supplier .btn-outline-primary:not(:disabled):not(.disabled):active
 {
    background-color:  #29BBC1;;
    border-color:  #29BBC1;;
    color: #fff;
    box-shadow: none;
}

.theme-supplier .button-back {
    border-color: #29BBC1;
    color: #29BBC1;
}

.theme-supplier .button-back:hover {
    background-color: #cff1f3;
}

.theme-supplier .button-cancel {
    border-color: rgba(28,31,57,0.25);
    color: #1C1F39;
}
