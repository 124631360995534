.V3 i.bxs-add-to-queue,
.V3 i.bx-add-to-queue {
    font-size: 20px;
}

.V3 i.bx-x-circle {
    color: #FF5E5E;
    font-size: 20px;
}

i.bx-circle,
i.bxs-check-circle,
i.bxs-minus-circle,
i.bxs-error {
    display: inline-block;
    width: 24px;
    height: 24px;
    font-size: 24px;
    margin-right: 5px;
}

