.tab-content-table .use-pagination-block-bottom .table.b-table {
    margin-bottom: 75px;
}

.tab-content-table .use-pagination-block-bottom.table-responsive {
    margin-bottom: 0px;
}

.pagination-block {

}

.pagination-block-showing {
    position: absolute;
    bottom: 30px;
    margin-left: 15px;
    width: 50%;
}

.pagination-block-showing span {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
}

.V3 .pagination-block-showing input.form-control {
    height: 27px;
    width: 37px;
    margin-left:5px;
    font-size: 12px;
    color: #1C1F39;
    text-align: center;
}

.pagination-block-paginator {
    position: absolute;
    bottom: 30px;
    right: 0px;
    margin-right: 15px;
    width: 50%;
}

.theme-repairer ul.pagination {
    margin: 0px;
}

.settings-page .pagination-block-showing,
.settings-page .pagination-block-paginator {
    bottom: -10px;
}

@media (max-width:425px){
    .pagination-block-showing {
        bottom: 60px;
        left: 0px;
        margin-left: 15px;
    }
    .pagination-block-paginator {
        right: unset;
        left: 0px;
        margin-left: 15px;
        width: auto;
    }

    .tab-content-table .use-pagination-block-bottom .table.b-table {
        margin-bottom: 105px;
    }
}
