.theme-repairer .wizard__step:not(:first-child) .wizard__step__line {
    position: absolute;
    width: 100%;
    left: -50%;
    top: 17px;
    height: 10px;
    /*background-color: #79B1BB;*/
}

.theme-repairer .wizard__step__indicator {
    background-color: #79B1BB;
}

.theme-repairer .wizard__step__indicator i {
    color: #79B1BB;
}

.theme-repairer .wizard__step.active.current .wizard__step__indicator {
    background-color: #FFFFFF;
    display: block;
    border: 2px solid #79B1BB;
}

.theme-repairer .wizard__step.active:not(:first-child) .wizard__step__line {
    background-color: #79B1BB;
}
