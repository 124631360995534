
.color-green {
  color: #17ae58;
}
.oem-color {
  color: #17a2ac;
}
.oem-purchase-detail__label {
  opacity: 1 !important;
}
.purchase-detail {
    position: relative;
    border: 1px solid rgba(27,30,56,.1);
    border-radius: 3px 3px 0 0;
    &:not(:first-child) {
        top: -1px;
        border-top: 0px;
        border-radius: 0 0 3px 3px;
    }

    &__body {
        padding: 1rem;
    }
    &__title {
        margin-bottom: 15px;
    }
    &__item {
        margin-bottom: 15px;
    }
    &__label {
        opacity: 0.5;
    }
}
