.theme-supplier .pagination .page-item .page-link {
    box-sizing: border-box;
    border: 1px solid rgba(27, 30, 56, 0.25);
    border-radius: 3px;
    background-color: rgba(27, 30, 56, 0.03);
}

.theme-supplier .pagination .page-item.active .page-link {
    background-color: #29BBC1 !important;
}

.theme-supplier .pagination .page-item.active .page-link,
.theme-supplier .pagination .page-item.active .page-link:hover,
.theme-supplier .pagination .page-item.active .page-link:focus {
    color: #fff;
}

.theme-supplier ul.pagination {
    margin: 0px;
}

.theme-supplier .pagination-block .pagination-block-showing {
    opacity: 0.5;
    color: #1C1F39;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
}

.page-item.disabled .page-link {
    text-decoration: none !important;
}