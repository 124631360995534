:root {
    --branding-5:  #00529C;
}
/* Repairer */
.theme-repairer.branding-5 {
    --theme-color: var(--branding-5);
}

.theme-repairer.branding-ccnsw .theme-color,
.theme-repairer.branding-5 .list-values .list-values-header .numb,
.theme-repairer.branding-5 .credit-view__context-menu .btn:hover,
.theme-repairer.branding-5 .part-list .new-part-item,
.theme-repairer.branding-5 .part-list .new-part-item i,
.theme-repairer.branding-5 .btn--remove:hover {
    color: var(--branding-5) !important;
}
.V3 .theme-repairer.branding-5 .site-sidebar .sidebar-menu > li.active > a,
.V3 .theme-repairer.branding-5 .site-sidebar .sidebar-menu li.active > a > span > i,
.V3 .theme-repairer.branding-5 .site-sidebar .sidebar-menu li > a:hover,
.V3 .theme-repairer.branding-5 .site-sidebar .sidebar-menu > li > a:hover .s-icon i{
    color: #FF0000 !important;
}
.V3 .theme-repairer.branding-5 .search-filter .search-filter__box{
    border-color: var(--branding-5) !important;
    color: var(--branding-5) !important;
}
.V3 .theme-repairer.branding-5 .search-filter .search-filter__box.search-filter__box-color-turquoise .search-filter__li i{
    color: var(--branding-5) !important;
}
.V3 .theme-repairer.branding-5 .search-filter .search-filter__input::placeholder{
    color: var(--branding-5) !important;
}

.V3.compact-sidebar .theme-repairer.branding-5 .site-sidebar .sidebar-menu li a:hover,
.theme-repairer.branding-5 .dropdown-options .btn,
.theme-repairer.branding-5 .dropdown-options .dropdown-arrow,
.theme-repairer.branding-5 .btn-outline-primary,
.theme-repairer.branding-5 .settings-page .primary-save-btn,
.theme-repairer.branding-5 .part-list ul.nav.nav-4 .nav-item.active::after {
    border-color: var(--branding-5);
}
.theme-repairer.branding-5 .dropdown-options.dropdown-actions .btn{
    background-color: var(--branding-5) !important;
}
.theme-repairer.branding-5 .dropdown-options.dropdown-actions .btn:hover{
    background-color: rgba(0, 82, 156, 0.32) !important;
}
.theme-repairer.branding-5 .site-content .position-notification-item .dashboard-panel .dashboard-panel-title-separator{
    border-top-color: var(--branding-5) !important;
}
.theme-repairer.branding-5 .site-content .position-notification-item .dashboard-panel .dashboard-panel-title-text .dashboard-tab-number{
    color: var(--branding-5) !important;
}

.theme-repairer.branding-5 .btn-primary,
.theme-repairer.branding-5 .btn-primary:hover {
    background-color: var(--branding-5);
    border-color: var(--branding-5);
}

.theme-repairer.branding-5 .btn-outline-primary:hover {
    background-color: #5a653f1a;
    border-color: var(--branding-5) !important;
}

.theme-repairer.branding-5 .not-active-button {
    border-color: var(--branding-5) !important;
    color: var(--branding-5) !important;
}

.theme-repairer.branding-5 .btn-primary:active:hover,
.theme-repairer.branding-5 .btn-primary:active:focus,
.theme-repairer.branding-5 .btn-primary:active.focus,
.theme-repairer.branding-5 .btn-primary.active:hover,
.theme-repairer.branding-5 .btn-primary.active:focus,
.theme-repairer.branding-5 .btn-primary.active.focus,
.theme-repairer.branding-5 .open > .btn-primary.dropdown-toggle:hover,
.theme-repairer.branding-5 .open > .btn-primary.dropdown-toggle:focus,
.theme-repairer.branding-5 .open > .btn-primary.dropdown-toggle.focus {
    background-color: var(--branding-5);
    border-color: var(--branding-5);
}

.theme-repairer.branding-5 .nav-tabs-2 .nav-link.active::after,
.theme-repairer.branding-5 .wizard__step.active:not(:first-child) .wizard__step__line {
    background-color: var(--branding-5);
}

.theme-repairer.branding-5 .pagination .page-item.active .page-link,
.theme-repairer.branding-5 .settings-page .update-btn {
    background-color: var(--branding-5) !important;
}

.theme-repairer.branding-5 .dropdown-options .dropdown-menu .dropdown-item:hover {
    background-color: #5a653f1a !important;
    color: var(--branding-5) !important;
}

.theme-repairer.branding-5 .btn-outline-primary:focus,
.theme-repairer.branding-5 .btn-outline-primary.focus,
.theme-repairer.branding-5 .btn-outline-primary:active,
.theme-repairer.branding-5 .btn-outline-primary.active,
.theme-repairer.branding-5 .btn-outline-primary:not(:disabled):not(.disabled):active,
.theme-repairer.branding-5 .btn-outline-primary:not(:disabled):not(.disabled).active {
    background-color: var(--branding-5);
    border-color: var(--branding-5);
}

.theme-repairer.branding-5 .parts-order-view__context-menu .btn:hover,
.V3 .theme-repairer.branding-5  .repairer-suppliers-page .repairer-suppliers-body .wizard-header-button-supplier-in .active,
.theme-repairer.branding-5 .settings-page .bx-cloud-upload,
.theme-repairer.branding-5 label.radio > input[type="radio"]:checked + span.icon i,
.theme-repairer.branding-5 label.checkbox > input[type="checkbox"]:checked + span.icon i,
.V3 .theme-repairer.branding-5 .rfq-add-page .wizard__body .wizard__body__step i.bx-cloud-upload {
    color: var(--branding-5);
}

.theme-repairer.branding-5 .parts-order-view-page .table.b-table > tbody > .table-active,
.theme-repairer.branding-5 .parts-order-view-page .table.b-table > tbody > .table-active > th,
.theme-repairer.branding-5 .parts-order-view-page .table.b-table > tbody > .table-active > td {
    background-color: #5a653f1a !important;
}

.V3 .theme-repairer.branding-5 .repairer-suppliers-page .repairer-suppliers-body .wizard-header-button-supplier-in .active-item a {
    color: var(--branding-5);
    background-color: #5a653f1a !important;
}

.theme-repairer.branding-5 .settings-page .edit-btn {
    border-color: var(--branding-5);
    color: var(--branding-5);
}

.theme-repairer.branding-5 .settings-page .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--branding-5);
    background-color: var(--branding-5);
}

.theme-repairer.branding-5 label.radio > span.icon,
.theme-repairer.branding-5 label.checkbox > span.icon {
    border-color: var(--branding-5) !important;
}

.V3 .theme-repairer.branding-5  .wizard__buttons .button-back {
    color: var(--branding-5) !important;
    border-color: var(--branding-5) !important;
}

.theme-repairer.branding-5 .rfq-order-page .offer-checked {
    background-color: #5a653f1a !important;
    border-color: #5a653f1a !important;
}

.theme-repairer.branding-5 .button-back {
    color: var(--branding-5) !important;
    border-color: var(--branding-5) !important;
}

.theme-repairer.branding-5 ~ #nprogress .bar {
    background: var(--branding-5);
}

/* Supplier */
.V3 .theme-supplier.branding-5  .site-sidebar .sidebar-menu li.active > a > span > i,
.V3 .theme-supplier.branding-5  .site-sidebar .sidebar-menu li > a:hover,
.V3 .theme-supplier.branding-5  .site-sidebar .sidebar-menu > li > a:hover .s-icon i,
.theme-supplier.branding-5  .list-values .list-values-header .numb,
.theme-supplier.branding-5  label.checkbox>input:checked+span.icon i,
.theme-supplier.branding-5  .settings-page .bx-cloud-upload,
.theme-supplier.branding-5  label.radio>input[type="radio"]:checked+span.icon i,
.theme-supplier.branding-5  label.checkbox>input[type="checkbox"]:checked+span.icon i {
    color: var(--branding-5);
}

.V3.compact-sidebar .theme-supplier.branding-5  .site-sidebar .sidebar-menu li a:hover,
.theme-supplier.branding-5  .dropdown-options .btn {
    border-color: var(--branding-5);
}

.theme-supplier.branding-5  .btn-primary,
.theme-supplier.branding-5  .btn-primary:hover,
.theme-supplier.branding-5  .btn-primary:focus,
.theme-supplier.branding-5 .btn-primary.focus,
.theme-supplier.branding-5 .btn-primary:active:hover,
.theme-supplier.branding-5 .btn-primary:active:focus,
.theme-supplier.branding-5 .btn-primary:active.focus,
.theme-supplier.branding-5 .btn-primary.active:hover,
.theme-supplier.branding-5 .btn-primary.active:focus,
.theme-supplier.branding-5 .btn-primary.active.focus,
.theme-supplier.branding-5 .open>.btn-primary.dropdown-toggle:hover,
.theme-supplier.branding-5 .open>.btn-primary.dropdown-toggle:focus,
.theme-supplier.branding-5 .open>.btn-primary.dropdown-toggle.focus,
.theme-supplier.branding-5 .settings-page .white-save-btn:hover,
.theme-supplier.branding-5 .btn-outline-primary:focus,
.theme-supplier.branding-5 .btn-outline-primary.focus,
.theme-supplier.branding-5 .btn-outline-primary:active,
.theme-supplier.branding-5 .btn-outline-primary.active,
.theme-supplier.branding-5 .btn-outline-primary:not(:disabled):not(.disabled):active,
.theme-supplier.branding-5 .pin-image input:checked ~ .pin-checkmark {
    background-color: var(--branding-5);
    border-color: var(--branding-5);
}

.V3 .V3.compact-sidebar .theme-supplier.branding-5 .site-sidebar .sidebar-menu li:hover > a > .s-text,
.branding-5 .files-tab-supplier
.branding-5 .block-uploader i.bx-cloud-upload,
.branding-5 .files-tab-supplier .block-uploader i.bx-download,
.branding-5 .files-tab-supplier .block-uploader i.bx-camera,
.branding-5 .files-tab-supplier .block-uploader a:hover {
    color: var(--branding-5) !important;
}

.theme-supplier.branding-5 .nav-tabs-2 .nav-link.active::after,
.theme-supplier.branding-5 .wizard__step.active:not(:first-child) .wizard__step__line {
    background-color: var(--branding-5);
}

.theme-supplier.branding-5 label.checkbox>span.icon {
    border: 2px solid var(--branding-5) !important;
}

.theme-supplier.branding-5 .dropdown-options .dropdown-arrow {
    border-left: 1px solid var(--branding-5);
}

.theme-supplier.branding-5 .dropdown-options .dropdown-menu .dropdown-item:hover {
    background-color: #5a653f1a !important;
    color: var(--branding-5) !important;
}

.theme-supplier.branding-5 .pagination .page-item.active .page-link,
.theme-supplier.branding-5 .update-btn,
.theme-supplier.branding-5 .settings-page .add-location-btn {
    background-color: var(--branding-5) !important;
}

.theme-supplier.branding-5 .pagination .page-item .page-link {
    border: #5a653f1a !important;
}

.theme-supplier.branding-5 .settings-page .white-save-btn {
    color: var(--branding-5);
    border-color: var(--branding-5);
}

.theme-supplier.branding-5 .credit-view__context-menu .btn:hover {
    background-color: #5a653f1a !important;
    color: var(--branding-5) !important;
}

.theme-supplier.branding-5 .tab-table,
.suppliers-customer-view-body__link {
    --color-theme: var(--branding-5);
}

.theme-supplier.branding-5 .settings-page .edit-btn {
    color: var(--branding-5);
    border-color: var(--branding-5);
}

.theme-supplier.branding-5 label.radio>span.icon {
    border-color: var(--branding-5) !important;
}

.branding-5 .supplier-rfq-view-page .sortable-chosen {
    border: 2px solid #5a653f4e !important;
    box-shadow: 0 0 0 0.3rem #5a653f1a !important;
    border-radius: 3px;
    box-sizing: border-box;
}

.theme-supplier.branding-5 .parts-order-view-page .header-part-customer-name,
.theme-supplier.branding-5 .parts-order-view-page .part-customer-name {
    border: 2px solid var(--branding-5) !important;
}

.theme-supplier.branding-5 .parts-order-view-page .pdf-upload-progress .progress-bar {
    background-color: var(--branding-5) !important;
}

.theme-supplier.branding-5 .parts-order-view-page .part-customer-name {
    background-color: #5a653f1a !important;
}

.theme-supplier.branding-5 .supplier-rfq-view-page .header-part-customer-name,
.theme-supplier.branding-5 .supplier-rfq-view-page .part-customer-name {
    border: 2px solid var(--branding-5) !important;
}

.theme-supplier.branding-5 .supplier-rfq-view-page .pdf-upload-progress .progress-bar,
.theme-supplier.branding-5 .supplier-rfq-view-page .pdf-upload-progress-2 .progress-bar {
    background-color: var(--branding-5) !important;
}

.theme-supplier.branding-5 .supplier-rfq-view-page .part-customer-name {
    background-color: #5a653f1a !important;
}

/* Other */
.branding-5 button.btn-animation {
    border-color: var(--branding-5);
    background-color: var(--branding-5);
}

.branding-5 .list-values .list-values-header.aqua-bg,
.branding-5 .list-values .list-values-header.orange-bg {
    background: #5a653f1a;
}

.branding-5 .tabs-component .tag-info {
    color: var(--branding-5) !important;
    background-color: #5a653f1a !important;
}

.branding-5 .search-filter__box__focused {
    box-shadow: 0 0 0 0.2rem #5a653f1a !important;
}

.branding-5 .request-time-extension button.btn.btn-secondary:active,
.branding-5 .request-time-extension button.btn.btn-secondary:focus,
.branding-5 .request-time-extension button.btn.btn-secondary:hover,
.branding-5 .request-time-extension button.btn.btn-secondary,
.V3 .branding-5 .dropdown-item.search-filter__dropdown-item:hover {
    background-color: #5a653f1a !important;
    color: var(--branding-5) !important;
}

.branding-5 .btn-primary:not(:disabled):not(.disabled):active:focus,
.branding-5 .btn-primary:not(:disabled):not(.disabled).active:focus,
.branding-5 .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #5a653f1a !important;
}

.branding-5 .files-tab-order .block-uploader i.bx-cloud-upload,
.branding-5 .files-tab-order .block-uploader i.bx-download,
.branding-5 .files-tab-order .block-uploader i.bx-camera,
.branding-5 .files-tab-order .block-uploader a:hover,
.branding-5 .block-uploader i.bx-cloud-upload,
.branding-5 .part-list .add-part {
    color: var(--branding-5) !important;
}

.branding-5 .form-control:focus,
.branding-5 .page-link:focus,
.branding-5 .btn-primary:focus, .btn-primary.focus,
.branding-5 .btn:focus, .btn.focus {
    box-shadow: 0 0 0 0.2rem #5a653f1a;
}

.branding-5 .custom-control-input:checked ~ label.custom-control-label::before {
    background-color: var(--branding-5);
    color: var(--branding-5);
    border-color: var(--branding-5);
}

.branding-5 .groups-page .group-list .active {
    background-color: #4a52341a !important;
}

.branding-5 .list-values .clickable:hover {
    color: var(--branding-5) !important;
}

.V3 .branding-5 .rfq-view-page .offer-checked {
    background-color: #5a653f1a !important;
}

.branding-5 .oem-color {
    color: var(--branding-5);
}

.V3 .branding-5 .navbar-brand-logo-svg {
    margin-left: 20px;
}

.branding-5 #nprogress .bar {
    height: 5px;
    transition-delay: 200ms;
    background: var(--branding-5);
}

.branding-5 .site-header .dropdown-item.active,
.branding-5 .site-header .dropdown-item:active {
    background-color: var(--branding-5);
}

.branding-5 .integrations-page >>> .table-tr-selected{
    background-color: #4a52341a !important;
    color: var(--branding-5) !important;
}

.branding-5 button.btn--download {
    background-color: var(--branding-5) !important;
    border-color: var(--branding-5) !important;
}

.branding-5 .header-part-customer-name,
.branding-5 .part-customer-name {

}
