::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
}

.V3 .page .form-material .form-control+.input-group-addon:not(:first-child) {
  border-left: 0;
}

.V3 .page .form-material .input-group-addon {
  padding: 0 0.75rem;
  font-size: 1.25rem;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
}

.auth-logo-svg {
  height: 55px;
  cursor: pointer;
}


.page .logo-text {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 2rem;
  color: #000000;
}

.page .logo-text:hover {
  color: #000000;
}

.auth-logo-svg {
  height: 55px;
  cursor: pointer;
}

.page {
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page .content {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1263px;
  margin: 0 auto;
}

.page .content, .page .content .column {
  height: auto;
}

.page, 
.page .content, .page .content .column {
  height: 100vh;
}

.page .content .form-container {
  padding: 15px;
  width: 100%;
  display: flex;
  align-items: center;
}

.background {
  display: none;
  width: 100%;
}

.background img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.form {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  line-height: 22px;
}

.form .form-group {
  margin-bottom: 0;
}

.form .logo {
  text-align: left;
}

.form .small {
  font-size: 14px;
}

.auth-header {
  position: relative;
  padding-right: 42px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(27, 30, 56, 0.1);
  padding-top: 25px;
}

.header-text {
  font-weight: bold;
  font-size: 32px;
}

.form {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  position: relative;
}

.form p {
  font-weight: 600;
  font-size: 16px;
}

.form label {
  color: #1c1f39;
  font-weight: 600;
  font-size: 16px;
}

.form .text-center {
  font-weight: 600;
  font-size: 16px;
}

.form a {
  color: #29bbc1;
  font-weight: 600;
  font-size: 16px;
}

.auth-header .close {
  position: absolute;
  right: 3px;
  top: 35px;
  width: 16px;
  height: 16px;
  display: block;
  cursor: pointer;
  font-size: 32px;
  color: #000000;
}

.recover-password {
  width: 100%;
  text-align: right;
}

.form button[type='submit'] {
  margin-top: 15px;
}

form {
  width: 100%;
}

.V3 body.V3 .form .multiselect__select {
  top: 10px;
}

.V3 body.V3 .form .multiselect__option {
  line-height: 30px;
}

.V3 body.V3 .form .multiselect--active .multiselect__select {
  top: 12px;
}

.form .multiselect__placeholder {
  color: #000000;
}

.V3 .form .multiselect__tags, .form .multiselect__input::placeholder {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
}

.form .multiselect__select {
  z-index: 1;
}

.form .multiselect__tags {
  height: 43px;
  border: 1px solid rgba(27, 30, 56, 0.2);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
}

.form .multiselect__tags::placeholder {
  color: #1C1F39;
  opacity: 0.5;
  font-weight: 600;
}

@media (min-width: 769px) {
  .page .content .form-container {
    display: block;
    width: 80%;
  }
  .background {
    display: block;
  }
  .recover-password {
    width: auto;
  }
  .auth-header {
    width: 100%;
  }
  .form {
    height: 100%;
    display: grid;
    align-items: center;
    box-sizing: border-box;
    margin-right: 0;
    flex-direction: column;
    grid-template-rows: 88px 1fr;
  }
}

@media (min-width: 500px) {
  .form {
    width: 435px;
  }
}