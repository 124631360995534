

/* WIZARD STEPPER
*******************************/

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.clearfix::after {
    display: block;
    content: "";
    clear: both;
}

/* Header Steps
*******************************/
.wizard {
    position: relative;
    width: 100%;
}

.card-new .wizard .sticky {
    position: sticky;
    top: 140px;
    z-index: 1000;
    background-color: #FFFFFF;
    height: 80px;
}

.wizard__divider {
    width: 100%;
    height: 10px;
    border: 5px solid rgba(27, 30, 56, 0.12);
}

.wizard__steps {
    list-style-type: none;
    text-align: justify;
    -ms-text-justify: distribute-all-lines;
    text-justify: distribute-all-lines;
    padding: 0;
    height: 70px;
    margin: 0 auto;
    position: relative;
}

.stretch {
    width: 100%;
    display: inline-block;
    font-size: 0;
    line-height: 0
}

.wizard__step {
    vertical-align: top;
    display: inline-block;
    text-align: center;
    position: relative;
}

.wizard__step:not(:first-child) .wizard__step__line {
    position: absolute;
    width: 100%;
    left: -50%;
    top: 17px;
    height: 10px;
    background-color: rgb(227 228 231);
}

.wizard__step:not(:first-child):not(:last-child) .wizard__step__line {
 /*   border-radius: 5px 0 0 5px;*/
}
.wizard__step:nth-child(2) .wizard__step__line {
       border-radius: 5px 0 0 5px;
}
.wizard__step:last-child .wizard__step__line {
    border-radius: 0 5px 5px 0;
}

.wizard__step__indicator {
    box-sizing: content-box;
    display: none;
    width: 26px;
    height: 26px;
    background-color: #51ABE4;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    top: 7px;
    z-index: 1;
}

.wizard__step__indicator i {
    font-size: 23px;
    color: #5E79FF;
}

.wizard__step.active.current .wizard__step__indicator {
    background-color: #FFFFFF;
    display: block;
    border: 2px solid #5E79FF;
}

.wizard__step.active:not(:first-child) .wizard__step__line {
    background-color: #5E79FF;
}


.wizard__step__label {
    position: absolute;
    left: 50%;
    margin-left: -10px;
    top: 48px;
    z-index: 1;
    font-weight: bold;
}

.wizard__step__label div {
    margin-left: -50%;
}
.wizard__step .wizard__step__label {
    opacity: 0.5;
    color: #1C1F39;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
}

.wizard__step.active .wizard__step__label {
    opacity: 1;
    font-weight: bold;
}

/* Wizard body
*******************************/
.wizard__body {
    position: relative;
    min-width: 573px;
    width: 573px;
    margin: 0px auto 0 auto;
    padding-top: 30px;
}

.wizard__body__step {
    /*padding: 16px;*/
}

.wizard__body .wizard__body__step .header {
    color: #1C1F39;
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
    margin-bottom: 5px;
}

.wizard__body .wizard__body__step .subheader {
    color: rgba(28, 31, 57, 0.75);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 15px;
}

.wizard__body span.indicate {
    color: #FF5E5E;
}

.wizard__arrow {
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    border: 1px solid #AEBAC4;
    top: 85px; /* height of step + body margin -15 */
    border-top-right-radius: 5px;
    background-color: #FFFFFF;
    border-left: none;
    border-bottom: none;
    transform: rotate(-45deg);
    z-index: 2;
    -webkit-transition: left 0.3s;
    -o-transition: left 0.3s;
    transition: left 0.3s;
}

.wizard__body__actions {
    position: absolute;
    bottom: 0px;
    height: 50px;
    width: 100%;
    border-top: 1px solid #AEBAC4;
    background-color: #B9C7D2;
}

.wizard__body__actions a {
    width: 120px;
    height: 100%;
    display: block;
    background-color: #51ABE4;
    color: white;
    font-weight: bold;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    -webkit-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
}

.wizard__body__actions a.disabled {
    cursor: not-allowed;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 0.5;
}

.wizard__body__actions a > .vgw-icon, .wizard__body__actions a > span {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.wizard__body__actions .vgw-icon::after {
    position: absolute;
    content: "";
    transform: rotate(-45deg);
    width: 8px;
    height: 8px;
    top: 50%;
    margin-top: -5px;
}

.vgw-icon.vgw-next::after {
    border-bottom: 2px solid white;
    border-right: 2px solid white;
}

.vgw-icon.vgw-prev::after {
    border-top: 2px solid white;
    border-left: 2px solid white;
    left: -10px;
}

.wizard__body__actions a:hover {
    background-color: #357FAE;
}

.wizard__body__actions a.final-step {
    background-color: #6EB165;
}

/* mobile */
.wizard__body.vgw-mobile {
    padding-left: 15px;
    padding-right: 15px;
    margin: 0px auto;
    min-width: auto;
    width: auto;
}

.wizard__step.vgw-mobile {
    display: none;
}

.wizard__step .wizard__step__line.vgw-mobile {
    display: none;
}

.wizard__step.active.vgw-mobile {
    display: inline-block;
}
.wizard__body table.b-table thead th {
    height: 48px;
}

table.table.b-table > thead > tr > th {
    background-color: rgba(27,30,56,0.12)  !important;
}

table.table td.checkbox,
table.table th.checkbox{
    width:25px;
    padding:10px 0px 10px 15px;
}


.V3 .wizard-header {
    position: relative;
    margin-bottom: 15px;
}

.V3 .wizard-header .wizard-header-name {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 5px;
}

.V3 .wizard-header .wizard-subheader-name {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
}

.V3 .wizard-header .wizard-header-button {
    position: absolute;
    bottom: 3px;
    right: 0;
    margin-left: 15px;
}

